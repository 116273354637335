import React, { Component } from 'react';
import '../css/App.css';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {formatCurrency} from "../util";

import { translate } from "../i18n/customI18nProvider";

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateBegin: null,
            dateEnd: null,
            bar_id: null
        };
    }

    componentDidMount() {
        const pname = window.location.pathname.split("/");

        let dateBegin = null;
        let dateEnd = null;
        let bar_id = null;
        
        if(pname.length === 3) {
            bar_id = pname[2];
            dateBegin = null;
            dateEnd = null;
        } else if(pname.length === 4) {
            bar_id = null;
            dateBegin = pname[2];
            dateEnd = pname[3];
        } else if(pname.length === 5) {
            bar_id = pname[2];
            dateBegin = pname[3];
            dateEnd = pname[4];
        }

        this.setState({
            dateBegin: dateBegin,
            dateEnd: dateEnd,
            bar_id: bar_id
        })

    }

    render(){

        let debit = this.props.statistics?.debit ? this.props.statistics.debit : 0;
        let deposits = this.props.statistics?.debit_split?.deposit ? this.props.statistics.debit_split.deposit : 0;
        let deposit_returns = this.props.statistics?.debit_split?.deposit_return ? this.props.statistics.debit_split.deposit_return : 0;
        let consumptions = debit - deposits - deposit_returns;


        let column1 = [];
        column1.push([formatCurrency(this.props.statistics.credit), translate('custom.loadings') + " cash"])
        if(!this.state.bar_id) {
            column1.push([formatCurrency(this.props.statistics.twint_credit_by_web), translate('custom.loadings') + " TWINT ( Web )"])
        }
        column1.push([formatCurrency(this.props.statistics.twint_credit_by_app), translate('custom.loadings') + " TWINT ( App )"])
        if (this.props.statistics.twint_credit_other) {
            column1.push([formatCurrency(this.props.statistics.twint_credit_other), translate('custom.loadings') + " TWINT"])
        }
        column1.push([formatCurrency(this.props.statistics.credit_card), translate('custom.loadings') + " " + translate('custom.cards') + " " + translate('custom.credit') + "/" + translate('custom.debit')])
        column1.push([formatCurrency(-this.props.statistics.checkout), translate('custom.refunds')])
        if(!this.state.bar_id) {
            column1.push([formatCurrency(-this.props.statistics.form_refund), translate('custom.form_refund')])
            column1.push([formatCurrency(-this.props.statistics.twint_refund), translate('custom.twint_refund')])
        }

        let column2 = [];
        column2.push([formatCurrency(this.props.statistics.entrance), translate('custom.entrances')])

        if ('debit_split' in this.props.statistics) {
            column2.push([formatCurrency(consumptions), translate('custom.consumptions')])
            if (deposits != 0)
                column2.push([formatCurrency(deposits), translate('custom.deposits')])
            if (deposit_returns != 0)
                column2.push([formatCurrency(deposit_returns), translate('custom.deposit_returns')])
        } else {
            column2.push([formatCurrency(this.props.statistics.debit), translate('custom.consumptions')])
        }

        if (!(this.state.dateBegin || this.state.dateEnd || this.state.bar_id)) {
            column2.push([formatCurrency(this.props.statistics.sold), translate('custom.bracelets_balance')])
            column2.push([formatCurrency(this.props.statistics.sold_twint), translate('custom.bracelets_twint_balance')])
            column2.push([formatCurrency(-this.props.statistics.unpaid), translate('custom.amounts_offered')])
            column2.push([formatCurrency(this.props.statistics.sold_unused), translate('custom.offer_unused')])
            column2.push([formatCurrency(this.props.statistics.donation), translate('custom.donation')])
        }


        const row_data = []
        for (let i=0; i<(column1.length > column2.length ? column1.length : column2.length); i++) {
            if (column1[i] && column2[i]) {
                row_data.push(column1[i].concat(column2[i]))
            } else if (column1[i]) {
                row_data.push(column1[i].concat(["", ""]))
            } else if (column2[i]) {
                row_data.push((["", ""]).concat(column2[i]))
            }
        }

        let column2_total = 0;
        if (!(this.state.dateBegin || this.state.dateEnd || this.state.bar_id)) {
            column2_total = debit +
            this.props.statistics.entrance +
            this.props.statistics.sold_unused +
            this.props.statistics.donation +
            this.props.statistics.sold -
            this.props.statistics.unpaid +
            this.props.statistics.sold_twint;
        } else {
            column2_total = debit +
            this.props.statistics.entrance;
        }

        let column1_total = 0;
        if(this.state.bar_id) {
            column1_total = this.props.statistics.credit +
            this.props.statistics.twint_credit_by_app +
            this.props.statistics.twint_credit_other +
            this.props.statistics.credit_card -
            this.props.statistics.checkout
        } else {
            column1_total = this.props.statistics.credit +
            this.props.statistics.twint_credit_by_web +
            this.props.statistics.twint_credit_by_app +
            this.props.statistics.twint_credit_other +
            this.props.statistics.credit_card -
            this.props.statistics.checkout -
            this.props.statistics.form_refund -
            this.props.statistics.twint_refund
        }

        const footer_data = [
            formatCurrency(column1_total),
            translate('custom.balance_at_checkout'),
            formatCurrency(column2_total),
            translate('custom.total')
        ]

        let view = (
            <>
                <h1 className={this.props.classes.titleTextStyle}>{translate('custom.movements')}</h1>
                <Box overflow="auto">
                    <Table style={{marginBottom: 20}} >
                        <TableHead className={this.props.classes.table_head} >
                            <TableRow>
                                <TableCell colSpan={2} className={this.props.classes.table_head}>{translate('custom.cash_movements')}</TableCell>
                                <TableCell colSpan={2} className={this.props.classes.table_head}>{translate('custom.turnover')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row_data.map((r_data, r_idx) => (
                                <TableRow
                                    key={r_idx}
                                >
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{r_data[0]}</TableCell>
                                    <TableCell className={this.props.classes.table_row}>{r_data[1]}</TableCell>
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{r_data[2]}</TableCell>
                                    <TableCell className={this.props.classes.table_row}>{r_data[3]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableHead className={this.props.classes.table_head} >
                            <TableRow>
                                <TableCell className={this.props.classes.table_head_amount} numeric="true">{footer_data[0]}</TableCell>
                                <TableCell className={this.props.classes.table_head}>{footer_data[1]}</TableCell>
                                <TableCell className={this.props.classes.table_head_amount} numeric="true">{footer_data[2]}</TableCell>
                                <TableCell className={this.props.classes.table_head}>{footer_data[3]}</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Box>
            </>
        );

        return (
            <div>
                <div className="noPrint">
                    <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto"}}>
                        {view}
                    </div>
                </div>
                <div className="print">
                    <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 550}}>
                        {view}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    table_head: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.primary.contrastText
    },
    table_head_amount: {
        textAlign: 'right',
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.primary.contrastText
    },
    table_amount: {
        textAlign: 'right',
        fontSize: 15
    },
    table_row: {
        fontSize: 15
    },
    titleTextStyle: {
        color: theme.palette.primary.main,
        fontSize: 24,
        bold: true,
        marginBottom: 15
    }
});

Account.defaultProps= {
    statistics: {}
};

export default withStyles(styles)(Account);
