import React, { useState } from 'react';
import {
    NumberInput,
    List,
    Datagrid,
    Edit,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    Filter,
    DateField,
    ReferenceField,
    Pagination,
    ReferenceManyField,
    NumberField,
    required,
    SimpleList,
    ArrayInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    FormTab,
    TopToolbar,
    FilterButton,
    CreateButton,
    DateTimeInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    useRefresh,
    ShowButton,
    ReferenceInput,
    AutocompleteInput,
    useRecordContext,
    // useNotify
} from 'react-admin';
import NfcIcon from '@material-ui/icons/Nfc';
import DeleteIcon from '@material-ui/icons/Delete';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import {formatCurrency, request, getChoices, languageCheckAndReload, formatCurrencyWithCode, convertMessageCodeToMessage, downloadFileFromResponse, getRequestOptions} from "../util";
import ClientImport from "../components/client/ClientImport";
import {translate} from "../i18n/customI18nProvider";
import config from '../config';
import Button  from '@material-ui/core/Button';

import { validatePositiveNumber } from '../validators/number_validators';
import Chip from '@material-ui/core/Chip';
import LinkIcon from '@material-ui/icons/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import ClientImportExcel from '../components/client/ClientImportExcel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const ClientIcon = NfcIcon;
import BlockIcon from '@material-ui/icons/Block';
import WarningIcon from '@material-ui/icons/Warning';
import Swal from 'sweetalert2';
import ClientAddEntrance from '../components/client/AddEntrance';
import { getRight } from '../services/storage';
import { TableRow } from '@material-ui/core';
import { Stack, Table, TableBody, TableCell, TableHead } from '@mui/material';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

const ClientListFilters = [
    <TextInput key={"uid"} label={translate('custom.uid')} source="uid" variant='outlined' alwaysOn />,
    <TextInput key={"old_uids"} label={translate('custom.old_uids')} source="old_uids" variant='outlined' />,
    <TextInput key={"short_id"} label={translate('custom.short_id')} source="short_id" variant='outlined' alwaysOn />,
    <TextInput key={"import_id"} label={translate('custom.import_id')} source="import_id" variant='outlined' />,
    <TextInput key={"name"} label={translate('custom.name')} source="name" variant='outlined' />,
    <NumberInput key={"balance"} label={translate('custom.balance')} source="balance" step={0.01} onWheel={(e) => e.target.blur()} variant='outlined' />,
    <NumberInput key={"age"} label={translate('custom.age')} source="age" step={1} onWheel={(e) => e.target.blur()} variant='outlined' />,
    <TextInput key={"ticket_id"} label={translate('custom.ticket_id')} source="ticket_id" variant='outlined' />,
    <TextInput key={"transaction_id"} label={translate('custom.transaction_id')} source="transaction_id" variant='outlined' />,
    <TextInput key={"order_number"} label={translate('custom.order_number')} source="order_number" variant='outlined' />,
    <ReferenceInput
        key={"entrance_id"}
        variant='outlined'
        label={translate('custom.entrance')}
        reference="entrances"
        source="entrance_id"
        filterToQuery={searchText => ({ name: searchText, type: ['entrance', 'unpaid'] })}
    >
        <AutocompleteInput optionText="name" />
    </ReferenceInput>
]

const ClientListFilter = (props) => (
    <Filter {...props}>
        {(props.right >= config.permissions.SUPERADMIN.value) ? (
            <ReferenceInput variant='outlined' label={translate('custom.event')} reference="events" source="event_id" filterToQuery={searchText => ({ name: searchText })} alwaysOn >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        ) : null}
        {ClientListFilters.map((filter, index) => {
            return filter;
        })}
    </Filter>
);

const ClientListActions = (props) => {
    
    const right = props.right;

    return (
        <TopToolbar>
            <FilterButton filters={ClientListFilters} />
            {(right <= config.permissions.ADMIN.value) ? (
                <ClientImportExcel />
            ) : null}
        </TopToolbar>
    )
};

const CustomShowButton = (props) => {
    const right = props.right;

    let link = `/client/${props.record.uid}`;
    if (props?.record?.archived) {
        link = `/event/${props.record.event_id}/client/${props.record.uid}`;
    }

    return (
        <Button
            // variant="contained"
            color="primary"
            size="small"
            style={{marginRight: 10}}
            startIcon={<LinkIcon />}
            href={link}
            target="_blank"
        >
            {translate('custom.view')}
        </Button>
    )
}

const Empty = (props) => {

    const right = props.right;

    return (
        <Box textAlign="center" m={10}>
            <ClientIcon style={{fontSize: '200px', color: 'gray'}} />
            <Typography variant="h4" paragraph>
                {translate('custom.no_clients_available')}
            </Typography>
            {(right <= config.permissions.ADMIN.value) ? (
                <Typography variant="body1" style={{marginTop: '10px', marginBottom: '10px'}}>
                    {translate('custom.import_from_an_excel_file')}
                </Typography>
            ) : null}
            <ClientImportExcel />
        </Box>
    )
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]} {...props} />;

const DefectiveBracelets = (props) => {
    if(!props.count) {
        return (null)
    }
    
    return (
        <h3>{translate('custom.defective_bracelet')} - {props.count}</h3>
    )
}

export class ClientList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            defective_wristbands: null,
            right: getRight()
        };

    }

    async componentDidMount() {

        let defective_wristbands = await request('/wristbands/defective/count', 'GET', null);
        // console.log(defective_wristbands);
        this.setState({defective_wristbands: defective_wristbands.count});

    }

    render() {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (

            <>
    
                {(this.state.right <= config.permissions.ADMIN.value) ? (
                    <ClientImport />
                ) : null}

                <DefectiveBracelets count={this.state.defective_wristbands} />
    
                <List {...this.props} hasCreate={false} actions={<ClientListActions right={this.state.right} />} filters={<ClientListFilter right={this.state.right} />} pagination={<PostPagination />} perPage={50} empty={<Empty right={this.state.right} />} >
                    {isSmall ? (
                        <SimpleList
                            primaryText={record => record.name ? record.name : record.uid }
                            secondaryText={record => record.name ? record.uid : record.short_id}
                            tertiaryText={record => formatCurrencyWithCode(record.balance, this.props.options.currencyCode)}
                        />
                    ) : (
                        <Datagrid>
                            {(this.state.right >= config.permissions.SUPERADMIN.value) ? (
                                <ReferenceField label={translate('custom.event')} source="event_id" reference="events" sortable={false} >
                                    <TextField source="name" />
                                </ReferenceField>
                            ) : null}
                            <TextField label="UID" source="uid" />
                            <TextField label={translate('custom.short_id')} source="short_id" />
                            <DateField label={translate('custom.creation_date')} source="creation_date" locales={translate('custom.OPTIONS.i18n_date_format')} showTime />
                            <NumberField label={translate('custom.balance')} source="balance" locales={translate('custom.OPTIONS.i18n_number_format')} options={{ style: 'decimal', minimumFractionDigits: 2 }}/>
                            <TextField label={translate('custom.name')} source="name" />
                            <TextField label={translate('custom.ticket_id')} source="ticket_id" />
                            <TextField label={translate('custom.import_id')} source="import_id" />
                            {/* <ReferenceField label={translate('custom.entrance')} source="entrance_id" reference="entrances" sortable={false} >
                                <TextField source="name" />
                            </ReferenceField> */}
                            {(this.state.right <= config.permissions.ADMIN.value) ? (
                                <EditButton />
                            ) : (
                                <CustomShowButton />
                            )}
                        </Datagrid>
                    )}
                </List>
    
            </>
        );
    }
};

const PostTitle = ({ record }) => {
    return <span>{translate('custom.client')} {record ? `"${record.name ? record.name : record.short_id}"` : ''}</span>;
};

async function refundTransaction(client_uid, tran_id=null) {
    
    let con;
    if(tran_id)
        con = window.confirm(translate('custom.do_you_want_to_refund_transaction'));
    else
        con = window.confirm(translate('custom.do_you_want_to_refund_client'));

    if(!con)
        return;

    let refund;
    if(tran_id !== null)
        refund = await request('/clients/refund/' + client_uid + '/transaction/' + tran_id, 'POST', null);
    else
        refund = await request('/clients/refund/' + client_uid, 'POST', null);
    

    if (refund.error) {
        alert(refund.error);
        return;
    }

    if(!refund.success) {
        alert(JSON.stringify(refund.data));
        // if(refund?.data?.success > 0)
        //     refresh();
    }

    if(refund.success) {
        // if(!refund.data || refund?.data?.success > 0)
        //     refresh();
        return;
    }

}

async function forceRefundMoney(client_uid) {

    // let con = window.confirm("Do you want to force refund ?");
    let con = window.confirm(translate('custom.do_you_want_to_force_refund'));
    if(!con)
        return;

    let money_refunded = await request('/clients/' + client_uid + "/checkoutforce", 'POST', null, {});
    console.log(money_refunded)

    if(money_refunded.error) {
        alert(money_refunded.error);
        return;
    }

    if(money_refunded.amount === 0) {
        alert(translate('custom.unable_to_refund') + ". " + translate('custom.balance') + " : " + formatCurrency(money_refunded.amount));
        return;
    }

    if(money_refunded?.warning && money_refunded?.can_be_refunded_by_twint) {
        alert(translate('custom.the_client_can_be_refunded_by_twint'));
        return;
    }

    alert(translate('custom.money_refunded_successfully') + " Amount to refund : " + formatCurrency(money_refunded.amount));

}

async function refundMoney(client_uid) {

    let con = window.confirm(translate('custom.do_you_want_to_refund_client'));
    if(!con)
        return;

    let money_refunded = await request('/clients/' + client_uid + "/checkout", 'POST', null, {});
    
    if(money_refunded.error) {
        alert(money_refunded.error);
        return;
    }

    if(money_refunded?.warning && money_refunded?.can_be_refunded_by_twint) {
        alert(translate('custom.the_client_can_be_refunded_by_twint'));
        return;
    }

    if(money_refunded.amount === 0) {
        alert(translate('custom.unable_to_refund') + ". " + translate('custom.balance') + " : " + formatCurrency(money_refunded.amount));
        return;
    }

    alert(translate('custom.money_refunded_successfully') + ". " + translate('custom.amount_to_refund') + " : " + formatCurrency(money_refunded.amount));

}

async function refundMoneyByCash(client_uid) {

    let con = window.confirm(translate('custom.do_you_want_to_refund_client_by_cash'));
    if(!con)
        return;

    let money_refunded = await request('/clients/' + client_uid + "/checkout?refund_by_cash=true", 'POST', null, {});
    
    if(money_refunded.error) {
        alert(money_refunded.error);
        return;
    }

    if(money_refunded.amount === 0) {
        alert(translate('custom.unable_to_refund') + ". " + translate('custom.balance') + " : " + formatCurrency(money_refunded.amount));
        return;
    }

    alert(translate('custom.money_refunded_successfully') + ". " + translate('custom.amount_to_refund') + " : " + formatCurrency(money_refunded.amount));

}


async function addMoney(client_uid, currency) {

    let amount = prompt(translate('custom.amount'), "");
    if(amount === null)
        return;
    amount = parseFloat(amount);
    if(Number.isNaN(amount)) {
        alert(translate('custom.please_enter_valid_amount'));
        return;
    }

    if(amount <= 0 || amount >= 300) {
        alert("Veuillez saisir un montant entre " + currency + " 1.- et 300.-");
        return;
    }

    let money_added = await request('/clients/' + client_uid + '?channelShort=' + config.channel_types.WEB.short, 'POST', null, {
        type: config.transaction_types.CREDIT,
        amount
    });

    if(money_added.error) {
        alert(money_added.error);
        return;
    }

    alert(translate('custom.money_added_successfully'));

}

async function addUnpaidMoney(client_uid, currency) {
    let amount = prompt(translate('custom.amount'), "");
    if(amount === null)
        return;
    amount = parseFloat(amount);
    if(Number.isNaN(amount)) {
        alert(translate('custom.please_enter_valid_amount'));
        return;
    }

    if(amount <= 0 || amount >= 300) {
        alert("Veuillez saisir un montant entre " + currency + " 1.- et 300.-");
        return;
    }

    let money_added = await request('/clients/' + client_uid + '?channelShort=' + config.channel_types.WEB.short, 'POST', null, {
        type: config.transaction_types.UNPAID,
        amount
    });

    if(money_added.error) {
        alert(money_added.error);
        return;
    }

    alert(translate('custom.money_added_successfully'));
}


const TransactionTypeView = ({record}) => {
    return (
        <>
            {translate('custom.' + record.type)}
            <br />
            {record.payment_type ? (<small style={{color: 'gray'}}>{translate('custom.by') + ' ' + translate('custom.' + record.payment_type)}</small>) : null}
            {record.order_number ? (<small style={{color: 'gray'}}>{record.order_number}</small>) : null}
        </>
    )
}

const TransactionIdView = ({record}) => {
    return (
        <>
            {record?.card_transaction_id ? (record.card_transaction_id) : null}
            {record?.twint_direct_merchant_tran_id ? (record.twint_direct_merchant_tran_id) : null}
            {record?.transaction_uuid ? (record.transaction_uuid) : null}
        </>
    )
}


const TwintRefundView = ({record}) => {

    const refresh = useRefresh();

    if(record.type === 'credit' && (record.card_transaction_id || record.twint_direct_merchant_tran_id)) {
        if(record.refunded) {
            return (
                <div style={{textAlign: 'center'}}>
                    <DoneIcon />
                </div>
            )
        } else {
            return (
                <div style={{textAlign: 'center'}}>
                    <CallMissedIcon style={{'cursor' : 'pointer'}}
                        onClick={async () => {
                            await refundTransaction(record.client_uid, record._id)
                            refresh();
                        }}
                    />
                </div>
            )
        }
    } else {
        return (
            <></>
        )
    }
}

const CommittedTransactionView = ({record}) => {
    if (record.committed == true) {
        return (
            <div style={{textAlign: 'center'}}>
                <DoneIcon />
            </div>
        )
    } else if (record.committed == false) {
        return (
            <div style={{textAlign: 'center'}}>
                <ClearIcon />
            </div>
        )
    } else {
        return (<></>)
    }
}

const CommittedTransactionIdView = ({record}) => {
    return (
        <div style={{textAlign: 'center'}}>
            {record.c_id}
        </div>
    )
}

const FormRefundView = (props) => {

    const record = props.record;
    const isSmall = props.isSmall ? props.isSmall : false;

    return (
        <div style={{width: "100%", marginTop: 10, marginBottom: 10}} align={isSmall ? "center" : "right"}>
            
            <Button
                variant="contained"
                color="primary"
                style={ isSmall ? {textTransform: 'none', fontSize: '15px', color :'white', width: 200, height: '5rem'} : {textTransform: 'none', fontSize: '15px', color :'white', width: 200, height: '3rem'}}
                startIcon={<LinkIcon />}
                onClick={() => {
                    formRefundPage(record.uid)
                }}
            >
                {translate('custom.form_refund_button')}
            </Button>
            
        </div>
    );
}

const AllTwintRefundView = (props) => {

    const record = props.record;
    const isSmall = props.isSmall ? props.isSmall : false;
    const refresh = useRefresh();

    let found = false;
    let transactions = record.transactions;

    if(transactions && transactions.length)
        for(let i=0; i<transactions.length; i++) {
            if(transactions[i].type === 'credit' && (transactions[i].card_transaction_id || transactions[i].twint_direct_merchant_tran_id) && !transactions[i].refunded) {
                found = true;
            }
        }

    return (
        <div style={{width: "100%"}} align={isSmall ? "center" : "right"}>
            
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={isSmall ? {marginTop: 10, marginBottom: 10, width: 200, height: '5rem'} : {marginTop: 20, marginBottom: 20, width: 200, height: '3rem'}}
                startIcon={<CallMissedIcon />}
                onClick={async () => {
                    await refundTransaction(record.uid, null)
                    refresh();
                }}
                disabled={!found}
            >
                {translate('custom.refund_all_twint')}
            </Button>
            
        </div>
    );
}

const AddTransactionView = (props) => {

    const record = props.record;
    const isSmall = props.isSmall ? props.isSmall : false;
    const currency = props.currency;
    const refresh = useRefresh();

    return (
        <Grid container spacing={2} style={{justifyContent: 'space-around'}}>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                <Button
                    fullWidth
                    variant="contained"
                    style={{
                        backgroundColor: config.dangerColor,
                        color: 'white'
                    }}
                    size="small"
                    onClick={async () => {
                        await refundMoney(record.uid)
                        refresh();
                    }}
                    // disabled={!found}
                >
                    {translate('custom.refund')}
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                <Button
                    fullWidth
                    variant="contained"
                    style={{
                        backgroundColor: config.dangerColor,
                        color: 'white'
                    }}
                    size="small"
                    onClick={async () => {
                        await refundMoneyByCash(record.uid)
                        refresh();
                    }}
                    // disabled={!found}
                >
                    {translate('custom.refund_by_cash')}
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                <Button
                    fullWidth
                    variant="outlined"
                    style={{
                        borderColor: config.dangerColor,
                        color: config.dangerColor
                    }}
                    size="small"
                    onClick={async () => {
                        await forceRefundMoney(record.uid)
                        refresh();
                    }}
                    // disabled={!found}
                >
                    {translate('custom.force_refund')}
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                <ClientAddEntrance record={record} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                <Button
                    fullWidth
                    variant="contained"
                    style={{
                        backgroundColor: config.successColor,
                        color: 'white'
                    }}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={async () => {
                        await addMoney(record.uid, currency)
                        refresh();
                    }}
                    // disabled={!found}
                >
                    {translate('custom.add_money')}
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                <Button
                    fullWidth
                    variant="outlined"
                    style={{
                        borderColor: config.successColor,
                        color: config.successColor
                    }}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={async () => {
                        await addUnpaidMoney(record.uid, currency)
                        refresh();
                    }}
                    // disabled={!found}
                >
                    {translate('custom.add_unpaid_money')}
                </Button>
            </Grid>
        </Grid>
    );

}

const downloadClientTransactions = async (uid, mode='xlsx') => {
    let response = await fetch(config.apiUrl + '/clients/' + uid + '/transactions/export/' + mode, getRequestOptions('GET'));
    if(response.status !== 200) {
        let responseJson = await response.json();
        Swal.fire({
            title: translate('custom.error'),
            text: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
            icon: 'error',
            confirmButtonText: translate('custom.ok'),
            confirmButtonColor: config.baseColor
        });
        return;
    } else {
        await downloadFileFromResponse(response, translate('custom.client') + '_' + uid + '.' + mode, false);
    }
}

const AllTransactionsView = (props) => {
    const record = props.record;
    const isSmall = props.isSmall ? props.isSmall : false;
    const currency = props.currency;
    // const refresh = useRefresh();

    return (
        <Grid container spacing={2}>

            {/* hr */}
            <Grid item xs={12}>
                <hr />
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" spacing={2} style={{justifyContent: 'space-between'}}>
                    <Typography variant="h6">{translate('custom.transactions')}</Typography>
                    <Grid>
                        <Button
                            onClick={() => {
                                downloadClientTransactions(record.uid, 'xlsx')
                            }}
                            variant="outlined"
                            style={{borderColor: config.baseColor, color: config.baseColor, marginRight: 2}}
                        >
                            {translate('custom.export') + ' - ' + translate('custom.xlsx')}
                        </Button>
                        <Button
                            onClick={() => {
                                downloadClientTransactions(record.uid, 'pdf')
                            }}
                            variant="outlined"
                            style={{borderColor: config.baseColor, color: config.baseColor}}
                            sx={{marginRight: 2}}
                        >
                            {translate('custom.export') + ' - ' + translate('custom.pdf')}
                        </Button>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Table size='small' sx={{width: '100%', overflowX: 'scroll'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('custom.date')}</TableCell>
                            <TableCell>{translate('custom.type')}</TableCell>
                            <TableCell>{translate('custom.transaction_id')}</TableCell>
                            <TableCell align='center'>{translate('custom.amount')}</TableCell>
                            <TableCell align='center'>{translate('custom.twint_refund')}</TableCell>
                            <TableCell align='center'>{translate('custom.committed')}</TableCell>
                            <TableCell align='center'>{translate('custom.commit_id')}</TableCell>
                            <TableCell align='center'>{translate('custom.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record?.transactions?.map((transaction) => (
                            <TableRow>
                                <TableCell>{new Date(transaction.datetime).toLocaleString()}</TableCell>
                                <TableCell><TransactionTypeView record={transaction} /></TableCell>
                                <TableCell><TransactionIdView record={transaction} /></TableCell>
                                <TableCell><AmountView currency={currency} record={transaction} /></TableCell>
                                <TableCell><TwintRefundView record={{ client_uid: record.uid, ...transaction }} /></TableCell>
                                <TableCell><CommittedTransactionView record={transaction} /></TableCell>
                                <TableCell><CommittedTransactionIdView record={transaction} /></TableCell>
                                <TableCell align='center'><DeleteTransactionView record={{ client_uid: record.uid, ...transaction }} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}

async function deleteTransaction(client_uid, transaction_id, force=false) {

    let c;
    if (force) {
        c = window.confirm(translate('custom.do_you_want_to_delete_this_and_linked_transaction'));
    } else {
        c = window.confirm(translate('custom.do_you_want_to_delete_transaction'));
    }

    if(!(c))
        return;

    let url = '/clients/transaction/' + client_uid + '/' + transaction_id;
    if (force) {
        url += '?force=true';
    }
    let transaction = await request(url, 'DELETE', null);
    if (transaction.error) {
        let error_message = transaction?.error_code ? convertMessageCodeToMessage(transaction.error_code) : transaction.error;
        alert(error_message)
        return;
    }

}

const DeleteTransactionView = (props) => {

    const { record } = props;
    const client_uid = record["client_uid"];
    const id = record["_id"]
    const refresh = useRefresh();

    return (
        <>
            {/* If 'payment_type' is 'wristband', then force delete ( with linked transaction ) */}
            {((record.payment_type === 'wristband') && ((record.type == config.transaction_types.CREDIT) || (record.type == config.transaction_types.BALANCE_TRANSFER_FROM_WRISTBAND))) ? (
                <DeleteSweepIcon style={{'cursor': 'pointer', color: config.dangerColor, marginLeft: 5}}
                    onClick={async () => {
                        await deleteTransaction(client_uid, id, true)
                        refresh();
                    }}
                />
            ) : (
                <DeleteIcon style={{'cursor': 'pointer', color: config.dangerColor}}
                    onClick={async () => {
                        await deleteTransaction(client_uid, id)
                        refresh();
                    }}
                />
            )}
        </>
    )
}

function showDetails(event_id, short_id, archived=false) {
    if(archived)
        window.open('/event/' + event_id + '/client/' + short_id, "_blank") //to open new page with event as it is archived
    else
        window.open('/client/' + short_id, "_blank") //to open new page without event as it is not archived
}

function formRefundPage(uid) {
    window.open('/refund/' + uid, '_blank')
}

const ClientDetailsView = (props) => {

    const { record } = props;
    // const short_id = record["short_id"];
    const uid = record["uid"];
    const event_id = record["event_id"];
    let currentDate = new Date();
    currentDate = currentDate.toISOString();

    // const [open] = this.state;
    let [snackbar, setSnackbar] = useState(false);

    return (
        <div>
            
            <Grid container direction="column">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button style={{textTransform: 'none', fontSize: '20px', color :'black'}} onClick={() => showDetails(event_id, uid, record?.archived)} >
                        {translate('custom.balance')} : {formatCurrency(record.balance)}
                        <InfoIcon style={{marginLeft: '10px'}} />
                    </Button>
                </Grid>
            </Grid>

            <Grid container direction="column">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button style={{textTransform: 'none', fontSize: '15px', color :'black'}}
                        onClick={() => {
                            navigator.clipboard.writeText(record.uid)
                            setSnackbar(true);
                            setTimeout(() => {
                                setSnackbar(false)
                            }, 2000);
                        }}
                    >
                        {translate('custom.uid')} : {record.uid}
                    </Button>
                    <Button style={{textTransform: 'none', fontSize: '15px', color :'black'}}
                        onClick={() => {
                            navigator.clipboard.writeText(record.short_id)
                            setSnackbar(true);
                            setTimeout(() => {
                                setSnackbar(false)
                            }, 2000);
                        }}>
                        {translate('custom.short_id')} : {record.short_id}
                    </Button>
                </Grid>
            </Grid>

            {(record.entrance_end_date < currentDate) && (
                <Grid container direction="column" xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            style={{textTransform: 'none', fontSize: '20px', color :'black', borderColor: 'gold'}} disabled
                            startIcon={<WarningIcon style={{color: 'gold'}} />}
                            variant="outlined"
                        >
                            {translate('custom.this_client_is_disabled')}
                        </Button>
                    </Grid>
                </Grid>
            )}
            

            <Snackbar open={snackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="success">
                    {translate('custom.copied')}
                </Alert>
            </Snackbar>

        </div>
    )

}

const OldUidsList = ({ record }) => {
    let old_uids = record.old_uids;
    if(old_uids && old_uids.length)
        return (
            <>
                <h3>{translate('custom.old_uids')}</h3>
                {
                    old_uids.map((old_uids) => (
                        <Chip label={old_uids} style={{margin: 1}} />
                    ))
                }
            </>
        );
    else
        return (
            <></>
        );
}

const CustomToolbar = props => {

    const refresh = useRefresh();
    let currentDate = new Date();
    currentDate = currentDate.toISOString();

    return (
        <Toolbar {...props}>
            <SaveButton {...props}
                disabled={props.pristine || props.invalid || props.saving || props.validating}
                style={{marginRight: 'auto'}}
                onSuccess={() => {
                    refresh();
                }}
            />
            {(props.record.entrance_end_date >= currentDate) && (
                <Button
                    onClick={async () => {

                        const c = await Swal.fire({
                            title: translate('custom.do_you_want_to_disable_client'),
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: config.baseColor,
                            cancelButtonText: translate('custom.cancel')
                        });


                        if(c.isConfirmed) {
                            await request('/clients/' + props.record.uid + '/disable', 'POST', null)
                            refresh();
                        }
                    }}
                    style={styles.disable_button}
                    variant="outlined"
                    startIcon={<BlockIcon />}
                    size="small"
                >
                    {translate('custom.disable')}
                </Button>
            )}
            {(props.record.entrance_end_date < currentDate) && (
                <Button
                    onClick={async () => {

                        const c = await Swal.fire({
                            title: translate('custom.do_you_want_to_enable_client'),
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: config.baseColor,
                            cancelButtonText: translate('custom.cancel')
                        });


                        if(c.isConfirmed) {
                            await request('/clients/' + props.record.uid + '/enable', 'POST', null)
                            refresh();
                        }
                    }}
                    style={styles.enable_button}
                    variant="outlined"
                    startIcon={<DoneIcon />}
                    size="small"
                >
                    {translate('custom.enable')}
                </Button>
            )}
            <DeleteButton mutationMode="pessimistic" />
        </Toolbar>
    )
};

const AmountView = (props) => {
    const { record } = props;
    return (
        <div style={{textAlign: 'right'}}>
            { props.currency + ' ' + formatCurrency(record.amount)}
        </div>
    )
}

export class ClientEdit extends React.Component {
// export const ClientEdit = (props) => {

    constructor(props) {
        super(props);
        this.state = {
            vouchers : [],
            currency: props.options.currency,
            currencyCode: props.options.currencyCode,
            currencySymbol: props.options.currencySymbol
        };
    }

    async componentDidMount() {

        let general_information = await request('/events/general', 'GET', null);
        if(general_information.error) {
            alert(general_information.error);
            return;
        }
        languageCheckAndReload(general_information.language);

        this.setState({
            currency: general_information.currencySymbol ? general_information.currencySymbol : this.state.currency,
            currencyCode: general_information.currencyCode ? general_information.currencyCode : this.state.currencyCode,
            currencySymbol: general_information.currencySymbol ? general_information.currencySymbol : this.state.currencySymbol
        });

        let vocs = [];
        let vouchers = await request('/vouchers', 'GET', null)
        if (vouchers.error) {
            alert(vouchers.error);
            return;
        }
        if(vouchers) {
            for(let i=0; i<vouchers.length; i++) {
                vocs.push({
                    id: vouchers[i]._id,
                    name: vouchers[i].name
                });
            }
        }
        if(vocs.length)
            vocs.sort((a, b) => a.name > b.name);
        this.setState({
            vouchers: vocs
        });
    }

    validateVoucherSelect = async (values) => {
        
        const errors = {};
        
        let vouchers = values.vouchers;
        
        errors.vouchers = [];
        
        let vouchers_length = 0;
        if(vouchers) {
            vouchers_length = vouchers.length;
        }
        for(let i=0; i<vouchers_length; i++) {
            errors.vouchers.push({});
        }
        for(let i=vouchers_length-1; i>=0; i--) {
            for (let j=0; j<i; j++) {
                if (vouchers && vouchers[i] && vouchers[j] && (vouchers[i]["voucher_id"] === vouchers[j]["voucher_id"])) {
                    errors.vouchers[i]['voucher_id'] = translate('custom.same_voucher_not_alowed');
                }
            }
        }
        
        return errors
    }

    render() {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <Edit undoable={false} title={<PostTitle />} {...this.props}>
                {/* <SimpleForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={this.validateVoucherSelect} > */}
                <TabbedForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={this.validateVoucherSelect} toolbar={<CustomToolbar />} >

                    <FormTab label={translate('custom.transactions')}>

                        {/* <h3>Old UIDs</h3> */}
                        <OldUidsList style={{"margin" : "10px"}} />

                        <ClientDetailsView />


                        <FormRefundView isSmall={isSmall} />

                        <AllTwintRefundView isSmall={isSmall} />

                        <AddTransactionView isSmall={isSmall} currency={this.state.currency} />

                        <AllTransactionsView isSmall={isSmall} currency={this.state.currency} />

                        {/* <ReferenceManyField
                            label={translate('custom.transactions')}
                            reference="transactions"
                            target="client_uid"
                            fullWidth
                        >
                            <Datagrid>
                                <TextField label="Date" source="date" />
                                <TransactionTypeView label="Type" />
                                <AmountView currency={this.state.currency} label={translate('custom.amount')} />
                                <TwintRefundView label={translate('custom.twint_refund')} style={{textAlign: 'center'}} />
                                <CommittedTransactionView label={translate('custom.committed')} />
                                <CommittedTransactionIdView label={translate('custom.commit_id')} />
                                <DeleteTransactionView />
                            </Datagrid>
                        </ReferenceManyField> */}

                    </FormTab>

                    <FormTab label={translate('custom.vouchers')}>
                        <ArrayInput source="vouchers">
                            <SimpleFormIterator disableReordering>
                                <SelectInput
                                    fullWidth label={translate('custom.voucher')} source="voucher_id" choices={this.state.vouchers} validate={required()}
                                    style={{"float": "left", "margin": "10px", "width": "40%"}} autoFocus
                                />
                                <NumberInput
                                    source="quantity" label={translate('custom.quantity')} validate={validatePositiveNumber}
                                    style={{"margin": "10px", "width": "40%"}}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>

                    <FormTab label={translate('custom.personal_info')}>
                        <Grid container style={{width: '100%'}} spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="name" label={translate('custom.name')} autoFocus variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="address" label={translate('custom.address')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="locality" label={translate('custom.locality')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="postal_code" label={translate('custom.postal_code')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="email" label={translate('custom.email')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <SelectInput fullWidth label="Age" source="age" choices={getChoices(config.entrance_ages)} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="phone_number" label={translate('custom.phone_number')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="ticket_id" label={translate('custom.ticket_id')} disabled={true} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="iban" label={translate('custom.iban')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="bic" label={translate('custom.bic')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <TextInput fullWidth source="bank" label={translate('custom.bank') + ' / ' + translate('custom.post')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                                <TextInput fullWidth source="establishment_address" label={translate('custom.establishment_address')} variant="outlined" />
                            </Grid>
                        </Grid>
                        
                    </FormTab>

                    <FormTab label={translate('custom.access')}>
                        <Grid container style={{width: '100%'}} spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <DateTimeInput fullWidth source="entrance_begin_date" label={translate('custom.entrance_begin_date')} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <DateTimeInput fullWidth source="entrance_end_date" label={translate('custom.entrance_end_date')} variant="outlined" />
                            </Grid>
                        </Grid>
                    </FormTab>

                {/* </SimpleForm> */}
                </TabbedForm>
            </Edit>
        )

    }

}

const styles = {
    disable_button: {
        color: 'red',
        borderColor: 'red',
        marginLeft: 'auto',
        marginRight: '20px'
    },
    enable_button: {
        color: config.baseColor,
        borderColor: config.baseColor,
        marginLeft: 'auto',
        marginRight: '20px'
    }
}