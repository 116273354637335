import React, { Component } from 'react';
import '../css/App.css';

import 'whatwg-fetch';

//import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Button  from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ActionHome from '@material-ui/icons/ArrowBack';
import config from "../config";
import InputAdornment from '@material-ui/core/InputAdornment';
import { nativeTranslate as translate } from '../i18n/customI18nProvider';
import braceletImage from '../images/bracelet2.png';
import backgroundImage from '../images/client-background-image.jpg';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FormControl, Hidden, InputLabel, Select, MenuItem } from '@material-ui/core';
import { convertMessageCodeToMessage, fillMessageVariable, request } from '../util';
import QRCode from "react-qr-code";
import Swal from 'sweetalert2';

import twintLogo from '../images/twint-logo.png';
import twintIconCamera from '../images/twint-icon-camera.svg';
import twintIconQrCode from '../images/twint-icon-qr_code.svg';

import bankBCV from '../images/bank-bcv.svg';
import bankCS from '../images/bank-cs.svg';
import bankPF from '../images/bank-pf.svg';
import bankRaiffeisen from '../images/bank-raiffeisen.svg';
import bankUBS from '../images/bank-ubs.svg';
import bankZKB from '../images/bank-zkb.svg';
import { getTheme, setTheme } from '../services/storage';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';

// Get whether it is android, ios or web
const platform = window.navigator.userAgent.toLowerCase().includes("android") ? "android" : (window.navigator.userAgent.toLowerCase().includes("iphone") || window.navigator.userAgent.toLowerCase().includes("ipad")) ? "ios" : "web";

export default class Pay extends Component {
    constructor(){
        super();

        this.STATES = {
            'PAY_WITH_TWINT' : 1,
            'PAY_WITH_TWINT_DIRECT' : 2,
            'PAY_WITH_OTHER_METHODS' : 3
        }

        this.payment_check_timer = null;

        let payment_type = this.getUrlParameter('payment_type');
        let twint_direct_token = this.getUrlParameter('twint_direct_token') ? this.getUrlParameter('twint_direct_token') : null;
        let embed = this.getUrlParameter('embed');
        let current_state = null
        if (payment_type == config.payment_types.TWINT.value) {
            current_state = this.STATES.PAY_WITH_TWINT
        } else if (payment_type == config.payment_types.TWINT_DIRECT.value) {
            current_state = this.STATES.PAY_WITH_TWINT_DIRECT
        } else {
            current_state = this.STATES.PAY_WITH_OTHER_METHODS
        }


        this.state = {
            amount: '',
            pay_transaction_fees: false,
            transaction_fees_multiplier: 0.015,
            transaction_fees: 0,
            general_information: {
                name: "Web App",
                acceptCreditCard: true
            },
            new_layout: true,
            current_state: current_state,
            embed: embed,
            short_id: '',
            name: null,
            currency: '',
            amounts_to_display: config.amounts_to_display,
            max_load_amount: config.maxLoadAmount,
            loading: false,
            page_loading: true,
            bank_selected: null,
            twint_direct_token: twint_direct_token,
            mode: null
        };

    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    componentDidMount = async () => {
        try {

            this.setStateAsync({ page_loading: true });

            let mode = this.props.match.params.mode;
            if(!mode) {
                mode = getTheme();
                if(!mode) {
                    // if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    //     mode = 'dark';
                    // } else {
                        mode = 'light';
                    // }
                }
            } else {
                setTheme(mode);
            }
            this.setState({mode: mode})

            let client_id = this.props.match.params.client;
            let response = await request("/clients/short/" + client_id.toUpperCase(), 'GET');
            if(response.error) {

                await this.setStateAsync({ page_loading: false });

                if(response.error_code) {
                    alert(convertMessageCodeToMessage(response.error_code));
                } else {
                    alert(response.error);
                }
                window.location.href = "/client";
                return;
            }

            let client_uid = response.uid;

            let general_information = await request(`/events/general?client_uid=${client_uid}`, 'GET');
            if(general_information.error) {

                await this.setStateAsync({ page_loading: false });

                alert(general_information.error);
                return;
            }
            
            let currency = general_information.currencySymbol ? general_information.currencySymbol : general_information.currencyCode;

            if(!general_information.acceptCreditCard) {
                return window.location = '/client/' + client_id.toUpperCase();
            }
            
            let new_state = {
                general_information,
                short_id: response.short_id,
                name: response.name,
                currency,
                amounts_to_display: general_information.amounts_to_display,
                max_load_amount: general_information.maxLoadAmount
            }

            await this.setStateAsync(new_state);

            if(this.state.current_state == this.STATES.PAY_WITH_TWINT_DIRECT && this.state.twint_direct_token) {
                this.start();
            }

            await this.setStateAsync({ page_loading: false });

        } catch (error){
            console.log(error.message);
            setTimeout(this.componentDidMount, 1000);
        }
    };

    componentWillUnmount() {
        clearInterval(this.payment_check_timer);
    }


    handleAmountChange = event => {
        let amount = parseFloat(event.target.value);
        if(isNaN(amount) || event.target.value === '') {
            amount = '';
        } else {
            amount = parseFloat(amount.toFixed(2));
            if(amount > this.state.max_load_amount) {
                amount = this.state.max_load_amount;
            }
        }
        this.setState({
            amount: '' + amount,
            // transaction_fees: (Number(amount) * this.state.transaction_fees_multiplier).toFixed(2),
            transaction_fees: (Math.ceil(Number(amount) * this.state.transaction_fees_multiplier * 100) / 100).toFixed(2)
        });
    }


    start = async () => {

        this.setState({
            loading: true
        });

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        let query_params = '?channelShort=' + config.channel_types.WEB.short;
        if (this.state.pay_transaction_fees) {
            query_params += '&pay_transaction_fees=true&transaction_fees_multiplier=' + this.state.transaction_fees_multiplier + '&transaction_fees=' + this.state.transaction_fees + '&amount=' + this.state.amount;
        }
        
        let amount;
        let transaction_fees = 0;

        if (this.state.current_state == this.STATES.PAY_WITH_TWINT_DIRECT && this.state.twint_direct_token) {

        } else {

    
            amount = parseFloat(parseFloat(this.state.amount).toFixed(2));
            if(isNaN(amount))
                amount = 0;

            if(parseFloat(amount) <= 0) {
                this.setState({
                    loading: false
                });
                return alert(translate('custom.please_enter_minimum_amount_of') + " " + this.state.currency + " 1.—");
            }

            if (this.state.pay_transaction_fees) {
                transaction_fees = parseFloat(parseFloat(this.state.transaction_fees).toFixed(2));
            }

    
            if(amount > this.state.max_load_amount) {
                this.setState({
                    loading: false
                });
                return alert(translate('custom.please_enter_maximum_amount_of') + " " + this.state.currency + " " + this.state.max_load_amount + ".—");
            }
            
            
            if(this.state.current_state == this.STATES.PAY_WITH_TWINT || this.state.current_state == this.STATES.PAY_WITH_TWINT_DIRECT)
                query_params += '&twint=true'
            else
                query_params += '&twint=false'
            
            if(this.state.embed == 'true')
                query_params += '&embed=true';

        }
        

        
        try {

            if (this.state.current_state == this.STATES.PAY_WITH_TWINT_DIRECT) {

                if (this.state.twint_direct_token) {

                } else {
                    let ret = await fetch(config.apiUrl + "/clients/" + this.props.match.params.client.toUpperCase() + "/twint_direct/checkin" + query_params,
                        {
                            method: 'POST',
                            headers,
                            body: JSON.stringify({
                                amount: parseFloat(parseFloat(amount + transaction_fees).toFixed(2))
                            })
                        }
                    );
                    ret = await ret.json();
                    if(ret.error || !ret.token) {
                        this.setState({
                            loading: false
                        });
                        return alert(translate('custom.error_occurred'));
                    }
                    this.setState({
                        bank_selected: null,
                        twint_direct_token: ret.token,
                        loading: false
                    });

                    // Set twint_direct_token in url
                    window.history.replaceState({}, null, window.location.href + '&twint_direct_token=' + ret.token);
                }

                if (this.payment_check_timer)
                    clearInterval(this.payment_check_timer);

                this.payment_check_timer = setInterval(async () => {
                    let ret = await fetch(config.apiUrl + "/clients/" + this.props.match.params.client.toUpperCase() + "/twint_direct/status/",
                        {
                            method: 'GET',
                            headers
                        }
                    );
                    ret = await ret.json();
                    if(ret.error) {
                        this.setState({
                            loading: false
                        });
                        clearInterval(this.payment_check_timer);
                        Swal.fire({
                            icon: 'warning',
                            title: translate('custom.error'),
                            text: translate('custom.payment_pending_contact_admin'),
                            confirmButtonColor: config.baseColor
                        }).then(() => {
                            this.props.history.push('/client/' + this.props.match.params.client.toUpperCase())
                        })
                    } else if(ret?.orderStatus == config.TWINT_PAYMENTS_ORDER_STATUS.IN_PROGRESS) {
                        this.setState({
                            loading: true
                        });
                    } else if (ret?.orderStatus == config.TWINT_PAYMENTS_ORDER_STATUS.SUCCESS) {
                        this.setState({
                            loading: false
                        });
                        clearInterval(this.payment_check_timer);
                        Swal.fire({
                            icon: 'success',
                            title: translate('custom.success'),
                            text: translate('custom.payment_success'),
                            confirmButtonColor: config.baseColor
                        }).then(() => {
                            this.props.history.push('/client/' + this.props.match.params.client.toUpperCase())
                        })
                    } else if (ret?.orderStatus == config.TWINT_PAYMENTS_ORDER_STATUS.FAILURE || ret?.pairingStatus == config.TWINT_PAYMENTS_PAIRING_STATUS.NO_PAIRING) {
                        this.setState({
                            loading: false
                        });
                        clearInterval(this.payment_check_timer);
                        Swal.fire({
                            icon: 'error',
                            title: translate('custom.error'),
                            text: translate('custom.payment_failed'),
                            confirmButtonColor: config.baseColor
                        }).then(() => {
                            this.props.history.push('/client/' + this.props.match.params.client.toUpperCase())
                        })
                    }
                }, 2000);

            } else {

                let checkin_body = {
                    amount: parseFloat(parseFloat(amount + transaction_fees).toFixed(2))
                }
        
                // hostname with protocol
                let domain = window.location.protocol + "//" + window.location.hostname;
                if (this.state.embed == 'true') {
                    checkin_body.url = domain + '/client/' + this.props.match.params.client.toUpperCase() + '/embed/' + (getTheme() ? getTheme() : 'light') + '?close=true';
                } else {
                    checkin_body.url = domain + '/client/' + this.props.match.params.client.toUpperCase();
                }

                let ret = await fetch(config.apiUrl + "/clients/short/" + this.props.match.params.client.toUpperCase() + "/pay" + query_params,
                    {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(checkin_body)
                    }
                );
                ret = await ret.json();
                if((ret != undefined) && ('link' in ret)) {
                    window.location = ret.link;
                } else {
                    if(ret.error) {
                        this.setState({
                            loading: false
                        });
                        return alert(ret.error);
                    } else {
                        this.setState({
                            loading: false
                        });
                        return alert(translate('custom.unknown_error'));
                    }
                }
            }
        } catch(e) {
            this.setState({
                loading: false
            });
            return alert(e.message);
        }
    };


    braceletHeaderRender(mode=null) {
        return (
            <div style={{textAlign: 'center'}}>
                <h3 style={ (mode == "dark") ? {color: config.baseColorDark} : {color: config.baseColor}}>{translate('custom.recharge_bracelet')} {this.state.name ? this.state.name : this.state.short_id}</h3>
                <img alt="bracelet" src={braceletImage} style={{width: '100%'}} />
            </div>
        )
    }

    amountInputRender(mode=null) {
        return (
            <div style={{textAlign: 'center'}}>
                
                {((this.state.current_state == this.STATES.PAY_WITH_TWINT || this.state.current_state == this.STATES.PAY_WITH_TWINT_DIRECT) && this.state?.general_information?.twint?.disable_refund_by_twint) ? (
                    <>
                        <br />
                        <small style={(mode == "dark") ? {color: config.dangerColorDark} : {color: config.dangerColor}}>{translate('custom.TEXT.twint_recharge_will_not_be_refunded_automatically')}</small>
                    </>
                ) : null}
                
                <br /><br />
                <TextField
                    id="amount-input"
                    style={{
                        backgroundColor: 'white',
                        boxShadow: '3px 3px 3px grey'
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><b style={{color: config.baseColor, fontSize: '20px'}}>{this.state.currency} - </b></InputAdornment>,
                        style: {
                            fontSize: '20px',
                            fontWeight: '600'
                        }
                    }}
                    inputProps={{
                        style: {
                            textAlign: 'center'
                        }
                    }}
                    variant="outlined"
                    onChange={(e) => {
                        this.setState({
                            amount: e.target.value,
                            transaction_fees: (Math.ceil(Number(e.target.value) * this.state.transaction_fees_multiplier * 100) / 100).toFixed(2)
                        });
                    }}
                    onBlur={this.handleAmountChange}
                    value={this.state.amount}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.start();
                        }
                    }}
                />
                <br />
                {Number(this.state.amount) > Number(this.state.max_load_amount) ? <small style={{color: 'red'}}>{translate('custom.maximum_limit_is') + ' ' + this.state.currency + ' ' + this.state.max_load_amount}</small> : null}

                <br />

                <h3 style={(mode == "dark") ? {color: 'white'} : {}}>{translate('custom.or')}</h3>

                <br />
                
                <small style={(mode == "dark") ? {color: 'white'} : {}}>{translate('custom.add_amount_directly')}</small>

                <br /><br />

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                            {this.state.amounts_to_display.map((value) => (
                                <Grid key={value} item>
                                    <Button variant="contained" color="primary" size="large" style={this.state.amount === value ? styles.amountInputButtonActive : styles.amountInputButton}
                                        onClick={() => {
                                            this.setState({
                                                amount: value,
                                                transaction_fees: (Math.ceil(Number(value) * this.state.transaction_fees_multiplier * 100) / 100).toFixed(2)
                                            });
                                        }}
                                    >
                                        <b>{this.state.currency} {value}</b>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                {(
                    this.state.transaction_fees
                    // && (this.state.current_state == this.STATES.PAY_WITH_TWINT || this.state.current_state == this.STATES.PAY_WITH_TWINT_DIRECT)
                ) ? (
                    <div style={{textAlign: 'center', marginTop: 10, backgroundColor: 'whitesmoke', borderRadius: '10px'}}>
                        <Stack direction="row" spacing={1} style={{alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
                            onClick={() => {
                                this.setState({
                                    pay_transaction_fees: !this.state.pay_transaction_fees
                                });
                            }}
                        >
                            <Checkbox
                                checked={this.state.pay_transaction_fees}
                                onChange={(e) => {
                                    this.setState({
                                        pay_transaction_fees: e.target.checked
                                    });
                                }}
                                style={{color: config.baseColor}}
                            />
                            <p
                                style={{fontWeight: 'bold', padding: 10}}
                            >
                                {fillMessageVariable(translate('custom.TEXT.donate_transaction_charges_while_recharging'), {currency_code: this.state.currency, amount: (Number(this.state.transaction_fees)).toFixed(2)})}
                            </p>
                        </Stack>
                    </div>
                ) : null}

            </div>
        )
    }

    twintDirectTokenWebRenderer() {
        return (
            <>
                <Grid container spacing={2} style={{backgroundColor: '#f5f5f5', flex: 1, justifyContent: 'space-around', paddingTop: 50}}>
                    <Grid item xs={12} sm={12} md={6} style={{textAlign: 'center'}}>
                        <div style={{backgroundColor: '#fefefe', display: 'inline-flex', borderRadius: 20}}>
                            <div style={{margin: 20}}>
                                <QRCode value={this.state.twint_direct_token} bgColor="#fefefe" />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{textAlign: 'center'}}>
                        <div style={{backgroundColor: '#fefefe', height: '100%', display: 'inline-flex', borderRadius: 20, minHeight: 100}}>
                            <div style={{margin: 'auto', marginLeft: 20, marginRight: 20}}>
                                {(String(this.state.twint_direct_token).split('').map((twint_direct_token_split) => {
                                    return (
                                        <span style={{backgroundColor: '#e6e6e6', padding: 15, margin: 5, borderRadius: 5, fontSize: 30, fontWeight: '600'}}>
                                            {twint_direct_token_split}
                                        </span>
                                    )
                                }))}
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{backgroundColor: '#f5f5f5', flex: 1, justifyContent: 'space-around', paddingTop: 50}}>
                    <Grid item xs={12} sm={12} md={4} style={{textAlign: 'center', padding: 20}}>
                        <img src={twintLogo} height={50} width={'auto'} style={{borderRadius: 10}} />
                        <p>{translate('custom.open_the_twint_app_of_your_bank_on_your_smartphone')}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} style={{textAlign: 'center', padding: 20}}>
                        <img src={twintIconCamera} height={50} width={'auto'} />
                        <p>{translate('custom.select_the_qr_code_symbol')}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} style={{textAlign: 'center', padding: 20}}>
                        <img src={twintIconQrCode} height={50} width={'auto'} />
                        <p>{translate('custom.point_your_smartphone_camera_at_the_qr_code_or_select_the_code_tab_and_enter_the_number_code')}</p>
                    </Grid>
                </Grid>
            </>
        )
    }

    twintDirectTokenAndroidIosCommonRenderer() {
        return (
            <>
                {/* Divider with text */}
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20}}>
                        <div style={{borderBottom: '1px solid gray', width: '40%'}}></div>
                        <div style={{paddingLeft: 10, paddingRight: 10}}>{translate('custom.or')}</div>
                        <div style={{borderBottom: '1px solid gray', width: '40%'}}></div>
                    </div>
                </Grid>

                {/* Helper Text */}
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <p>{translate('custom.enter_this_code_in_your_twint_app')}</p>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <div style={{backgroundColor: '#fefefe', height: '100%', display: 'inline-flex', borderRadius: 20, minHeight: 100}}>
                        <div style={{margin: 'auto', marginLeft: 20, marginRight: 20}}>
                            {(String(this.state.twint_direct_token).split('').map((twint_direct_token_split) => {
                                return (
                                    <span style={{backgroundColor: '#e6e6e6', padding: 15, margin: 5, borderRadius: 5, fontSize: 30, fontWeight: '600'}}>
                                        {twint_direct_token_split}
                                    </span>
                                )
                            }))}
                        </div>
                    </div>
                </Grid>

                {/* outlined button to copy the code to clipboard */}
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Button
                        variant="outlined"
                        size="large"
                        style={{color: '#262626', borderColor: '#262626', marginTop: 20, marginBottom: 20, textTransform: 'none'}}
                        onClick={() => {
                            navigator.clipboard.writeText(this.state.twint_direct_token).then(() => {
                                alert(translate('custom.copied_to_clipboard'));
                            });
                        }}
                    >
                        {translate('custom.copy_code')}
                    </Button>
                </Grid>
            </>
        )
    }

    twintDirectTokenAndroidRenderer() {
        return (
            <>

                <Grid container spacing={2} style={{backgroundColor: '#f5f5f5', flex: 1, justifyContent: 'space-around', paddingTop: 50}}>

                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            size="large"
                            style={{color: 'white', backgroundColor: '#262626', marginBottom: 20, textTransform: 'none', fontSize: 20}}
                            onClick={() => {
                                window.location.href = "intent://payment#Intent;action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=" + this.state.twint_direct_token + ";S.startingOrigin=EXTERNAL_WEB_BROWSER;S.browser_fallback_url=;end";
                            }}
                        >
                            {translate('custom.switch_to_the_app_now')}
                        </Button>
                    </Grid>

                    {this.twintDirectTokenAndroidIosCommonRenderer()}

                </Grid>

            </>
        )
    }

    twintDirectTokenIosRenderer() {
        return (
            <>
            
                <Grid container spacing={2} style={{backgroundColor: '#f5f5f5', flex: 1, justifyContent: 'space-around', paddingTop: 50}}>


                    <Grid item xs={4} style={{textAlign: 'center'}}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer2://'
                                })
                            }}
                        >
                            <img src={bankUBS} width="70%" height="auto" />
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: 'center'}}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer6://'
                                })
                            }}
                        >
                            <img src={bankRaiffeisen} width="70%" height="auto" />
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: 'center'}}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer7://'
                                })
                            }}
                        >
                            <img src={bankPF} width="70%" height="auto" />
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: 'center'}}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer3://'
                                })
                            }}
                        >
                            <img src={bankZKB} width="70%" height="auto" />
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: 'center'}}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer4://'
                                })
                            }}
                        >
                            <img src={bankCS} width="70%" height="auto" />
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: 'center'}}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer5://'
                                })
                            }}
                        >
                            <img src={bankBCV} width="70%" height="auto" />
                        </Button>
                    </Grid>


                    {/* Material UI dropdown */}
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Select
                            fullWidth
                            variant="outlined"
                            placeholder={translate('custom.select_bank')}
                            labelId="bank-select-label"
                            id="bank-select"
                            value={this.state.bank_selected}
                            onChange={(event) => {
                                this.setState({
                                    bank_selected: event.target.value
                                })
                            }}
                            style={{width: '100%'}}
                        >
                            <MenuItem value="twint-issuer1://">TWINT - autre banque &amp; prepaid</MenuItem>
                            <MenuItem value="twint-issuer25://">AEK TWINT</MenuItem>
                            <MenuItem value="twint-issuer30://">AKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer12://">AppKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer16://">BCF TWINT</MenuItem>
                            <MenuItem value="twint-issuer10://">BCGE TWINT</MenuItem>
                            <MenuItem value="twint-issuer17://">BCJ TWINT</MenuItem>
                            <MenuItem value="twint-issuer18://">BCN TWINT</MenuItem>
                            <MenuItem value="twint-issuer5://">BCV TWINT</MenuItem>
                            <MenuItem value="twint-issuer13://">BCVs TWINT</MenuItem>
                            <MenuItem value="twint-issuer23://">BEKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer26://">BLKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer33://">Baloise TWINT</MenuItem>
                            <MenuItem value="twint-issuer4://">Credit Suisse TWINT</MenuItem>
                            <MenuItem value="twint-issuer15://">GKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer22://">GLKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer32://">LUKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer21://">Migros Bank TWINT</MenuItem>
                            <MenuItem value="twint-issuer29://">NKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer8://">OKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer7://">PostFinance TWINT</MenuItem>
                            <MenuItem value="twint-issuer28://">Radicant TWINT</MenuItem>
                            <MenuItem value="twint-issuer6://">Raiffeisen TWINT</MenuItem>
                            <MenuItem value="twint-issuer14://">SGKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer24://">SHKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer31://">SZKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer27://">Swissquote TWINT</MenuItem>
                            <MenuItem value="twint-issuer19://">TKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer2://">UBS TWINT</MenuItem>
                            <MenuItem value="twint-issuer34://">UKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer20://">Valiant TWINT</MenuItem>
                            <MenuItem value="twint-issuer3://">ZKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer9://">ZugerKB TWINT</MenuItem>
                            <MenuItem value="twint-issuer35://">yuh TWINT</MenuItem>
                        </Select>
                    </Grid>


                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Button
                            disabled={!this.state.bank_selected}
                            variant="contained"
                            size="large"
                            style={this.state.bank_selected ? {color: 'white', backgroundColor: '#262626', marginBottom: 20, textTransform: 'none', fontSize: 20} : {color: '#ccc', backgroundColor: '#999', marginBottom: 20, textTransform: 'none', fontSize: 20}}
                            onClick={() => {
                                window.location.href = this.state.bank_selected + 'applinks/?al_applink_data={"app_action_type": "TWINT_PAYMENT","extras": {"code": "' + this.state.twint_direct_token + '"},"referer_app_link": {"target_url": "", "url": "", "app_name": "EXTERNAL_WEB_BROWSER"}, "version": "6.0" }';
                            }}
                        >
                            {translate('custom.switch_to_the_app_now')}
                        </Button>
                    </Grid>


                    {this.twintDirectTokenAndroidIosCommonRenderer()}

                </Grid>

            </>
        )
    }

    getNewRender(mode=null) {

        return (
            <div style={(mode == 'dark') ? styles.backgroundWithImageDark : styles.backgroundWithImage}>

                <Hidden smUp>
                    <Fab color="primary" aria-label="add" style={{position: 'fixed', right: 10, bottom: 30, zIndex: 500, backgroundColor: config.baseColor}}>
                        <ArrowDropDownIcon style={{fontSize: '60px'}} onClick={() => {
                            this.viewEnd.scrollIntoView({behavior: 'smooth'})
                        }} />
                    </Fab>
                </Hidden>
                

                <Grid container alignItems='center' justifyContent="center" direction="column">
                    <Grid item xs={12} sm={12} md={8} lg={6}>

                        <div style={(mode == 'dark') ? styles.glassBackgroundDark : styles.glassBackground}>

                            <div style={(mode == 'dark') ? styles.glassBackgroundMainDark : styles.glassBackgroundMain}>
                                
                                {/* Show below icon if can go back */}
                                {this.props.history.length > 2 ? (
                                    <IconButton
                                        onClick={() => {
                                            clearInterval(this.payment_check_timer);
                                            this.props.history.goBack();
                                        }}
                                        style={{
                                            color: 'white',
                                            backgroundColor: config.baseColor,
                                            padding: '5px'
                                        }}
                                    >
                                        <ActionHome style={{fontSize: '20px'}} />
                                    </IconButton>
                                ) : null}
                                
                                {this.braceletHeaderRender(mode)}

                                {!this.state.twint_direct_token ? this.amountInputRender(mode) : null}

                            </div>


                        </div>

                        

                        <div style={(mode == "dark") ? styles.glassBackgroundFooterDark : styles.glassBackgroundFooter}>

                            {this.state.twint_direct_token ? (
                                <>

                                    {(platform == 'web') ? (
                                        this.twintDirectTokenWebRenderer()
                                    ) : null}

                                    {(platform == 'android') ? (
                                        this.twintDirectTokenAndroidRenderer()
                                    ) : null}

                                    {(platform == 'ios') ? (
                                        this.twintDirectTokenIosRenderer()
                                    ) : null}

                                </>
                            ) : null}
                            
                            {!this.state.twint_direct_token ? (
                                <div align="center">
                                    <Button variant="contained" size="large" onClick={this.start}
                                        style={{
                                            color: 'white',
                                            backgroundColor: 
                                                (this.state.loading || this.state.amount === '' || isNaN(Number(this.state.amount)) || Number(this.state.amount) <= 0) ? 
                                                'gray' : 
                                                config.baseColor
                                        }}
                                        disabled={this.state.loading || this.state.amount === '' || isNaN(Number(this.state.amount)) || Number(this.state.amount) <= 0}
                                        startIcon={this.state.loading ? <CircularProgress size={20} style={{color: 'white'}} /> : null}
                                    >
                                        {this.state.loading ? 
                                            translate('custom.loading') : (
                                                (((!isNaN(Number(this.state.amount)) && Number(this.state.amount)) > 0) ?
                                                    (translate('custom.pay') + ' ' + this.state.currency + ' ' + (Number(this.state.amount) + Number(this.state.pay_transaction_fees ? this.state.transaction_fees : 0)).toFixed(2))
                                                    : translate('custom.pay'))
                                            )
                                        }
                                    </Button>
                                </div>
                            ) : null}
                        </div>

                    </Grid>

                </Grid>

                <div ref={(el) => { this.viewEnd = el; }}></div>

            </div>
        )
    }


    render() {

        let mode = this.state.mode;


        if (this.state.page_loading) {
            return (
                <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress style={{color: config.baseColor}} />
                </div>
            )
        }

        return (
            <div style={{height: '100vh'}}>
                {this.getNewRender(mode)}
            </div>
        )
    }
}


const styles = {
    amountInputButton: {
        color: config.baseColor,
        backgroundColor: 'white'
    },
    amountInputButtonActive: {
        color: 'white',
        backgroundColor: config.baseColor
    },
    glassBackgroundMain: {
        background: 'rgba(255, 255, 255, 0.46)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        borderRadius: '20px'
    },
    glassBackgroundMainDark: {
        background: '#181a1b',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        borderRadius: '20px',
        border: '1px solid gray'
    },
    glassBackground: {
        background: 'rgba(255, 255, 255, 0.17)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px'
    },
    glassBackgroundDark: {
        background: '#181a1b',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px'
    },
    glassBackgroundFooter: {
        background: 'rgba(255, 255, 255, 0.46)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(10px)',
        padding: '10px'
    },
    glassBackgroundFooterDark: {
        background: '#181a1b',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(10px)',
        padding: '10px'
    },
    backgroundWithImage: {
        backgroundImage: "linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(" + backgroundImage + ")",
        height: '100vh',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'auto',
        backgroundPosition: 'center center',
        /* mobile viewport bug fix */
        minHeight: '-webkit-fill-available'
    },
    backgroundWithImageDark: {
        background: '#181a1b',
        height: '100vh',
        width: '100%'
    }
}