import React from 'react';
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    required,
    SimpleList,
    Filter,
    Pagination,
    Toolbar,
    SaveButton,
    useRefresh,
    NumberField,
    SelectInput,
} from 'react-admin';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Actions from '../components/reactAdmin/Actions';
import { Grid } from '@material-ui/core';
import { translate } from '../i18n/customI18nProvider';
import { request } from '../util';
import Chip from '@material-ui/core/Chip';
import RestoreIcon from '@material-ui/icons/Restore';
import Button from '@material-ui/core/Button';
import { validatePositiveNumber, validatePositiveNumberNonRequired } from '../validators/number_validators';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import config from '../config';
import { getTokenExists } from '../services/token.service';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


export const DepositIcon = AssignmentReturnedIcon;

const QuickFilter = ({ label }) => {
    return <Chip style={{margin: 8, marginLeft: 0}} label={translate(label)} />;
};

const CustomListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" variant='outlined' alwaysOn />
        <QuickFilter source="deleted_only" label={'custom.deleted_only'} defaultValue={true} />
        <QuickFilter source="with_deleted" label={'custom.with_deleted'} defaultValue={true} />
    </Filter>
);

const CustomListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const DepositType = ({record}) => {
    if(!record) {
        return null;
    }
    const deposit_type = record.deposit_type;
    const found = config.deposit_types.find((t) => t.id === deposit_type);
    if(!found) {
        return null;
    }
    return (
        <Chip label={found.name} />
    )

}

const CustomButtons = ({record}) => {
    const refresh = useRefresh();
    if(record.deleted) {
        return (
            <>
                <Button
                    color="primary"
                    startIcon={<RestoreIcon />}
                    onClick={async () => {
                        try {
                            await request('/deposits/' + record._id + '/restore', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{marginLeft: '10px', marginRight: '10px'}}
                >
                    {translate('custom.restore')}
                </Button>
            </>
        )
    } else {
        return (
            <>
                <EditButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
            </>
        )
    }
}

export class DepositList extends React.Component {

    constructor() {
        super();

        this.state = {
            deposit_return_token_exists: false
        };
    }

    async componentDidMount() {
        let deposit_return_token_exists = false;
        try {
            let token_exists = await getTokenExists({permission: 'deposit_return'});
            if (!token_exists.error) {
                deposit_return_token_exists = token_exists?.exists ? true : false;
            }
        } catch(e) {
            deposit_return_token_exists = false;
        }
        
        this.setState({
            deposit_return_token_exists
        });
    }

    render() {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <List
                {...this.props}
                actions={<Actions />}
                filters={<CustomListFilter />}
                pagination={<CustomListPagination />}
                perPage={50}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => record.name}
                    />
                ) : (
                    <Datagrid>
                        <TextField label={translate('custom.name')} source="name" />
                        <NumberField label={translate('custom.price')} source="price" locales={translate('custom.OPTIONS.i18n_number_format')} options={{ style: 'decimal', minimumFractionDigits: 2 }} />
                        {(this.state.deposit_return_token_exists) && (
                            <DepositType label={translate('custom.type')} />
                        )}
                        <NumberField label={translate('custom.delta')} source="delta" />
                        <CustomButtons />
                    </Datagrid>
                )}
            </List>
        )
    }
}


const CustomTitle = ({ record }) => {
    return <span>{translate('custom.deposit')} {record ? `"${record.name}"` : ''}</span>;
};

const ColorIconRender = ({ record }) => {
    return (
        <>
            <Button
                startIcon={<FiberManualRecordIcon style={{color: record.colorCode}} />}
                disabled={true}
                size="small"
                style={{color: 'black'}}
            >
                {translate('custom.colors.' + record.id)}
            </Button>
        </>
    );
};

const deposit_form = (deposit_return_token_exists=false) => {
    return (
        <>
            <Grid container spacing={3} style={{width: '100%'}}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                        fullWidth
                        source="name"
                        label={translate('custom.name')}
                        validate={required()}
                        autoFocus
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                        fullWidth
                        source="price"
                        label={translate('custom.price')}
                        validate={validatePositiveNumber}
                        variant="outlined"
                    />
                </Grid>
                {(deposit_return_token_exists) && (
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <SelectInput
                            fullWidth
                            source="deposit_type"
                            label={translate('custom.type')}
                            choices={config.deposit_types}
                            variant="outlined"
                            resettable
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                        fullWidth
                        source="delta"
                        label={translate('custom.delta')}
                        validate={validatePositiveNumberNonRequired}
                        variant="outlined"
                        options={{
                            // helper text
                            helperText: <Tooltip title={translate('custom.TEXT.deposit_delta_info_text')} style={{marginLeft: -10, cursor: 'pointer'}}>
                                            <InfoIcon />
                                        </Tooltip>
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <SelectInput
                        fullWidth
                        source="color"
                        label={translate('custom.color')}
                        defaultValue={config.consumption_colors.find((c) => c.id === 'green')?.id}
                        choices={config.consumption_colors}
                        resettable={true}
                        optionText={<ColorIconRender />}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <SelectInput
                        fullWidth
                        source="return_color"
                        label={translate('custom.return_color')}
                        choices={config.consumption_colors}
                        resettable={true}
                        optionText={<ColorIconRender />}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <TextInput
                        fullWidth
                        source="tag"
                        label={translate('custom.tag')}
                        style={{"width": "100%"}}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <TextInput
                        fullWidth
                        source="return_tag"
                        label={translate('custom.return_tag')}
                        style={{"width": "100%"}}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </>
    )
}




export class DepositEdit extends React.Component {
    constructor() {
        super();

        this.state = {
            deposit_return_token_exists: false
        };
    }

    async componentDidMount() {
        let deposit_return_token_exists = false;
        try {
            let token_exists = await getTokenExists({permission: 'deposit_return'});
            if (!token_exists.error) {
                deposit_return_token_exists = token_exists?.exists ? true : false;
            }
        } catch(e) {
            deposit_return_token_exists = false;
        }
        
        this.setState({
            deposit_return_token_exists
        });
    }

    render() {

        return (
            <Edit title={<CustomTitle />} {...this.props}>
                <SimpleForm redirect="list" variant="standard" warnWhenUnsavedChanges>

                    {deposit_form(this.state.deposit_return_token_exists)}

                </SimpleForm>
            </Edit>
        )
    }

}

const SaveAndAddNewToolbarButtons = (props) => {
    const refresh = useRefresh();
    return (
        <Toolbar {...props}>
            <SaveButton
                {...props}
                disabled={props.pristine || props.invalid || props.saving || props.validating}
                label="ra.action.save"
                redirect="edit"
                submitOnEnter={false}
                style={{marginRight: 10}}
            />
            <SaveButton
                {...props}
                disabled={props.pristine || props.invalid || props.saving || props.validating}
                label={translate('custom.save_and_add')}
                redirect={() => {
                    refresh();
                    return 'create';
                }}
                submitOnEnter={true}
            />
        </Toolbar>
    )
}

export class DepositCreate extends React.Component {

    constructor() {

        super();

        this.state = {
            deposits: [],
            deposit_return_token_exists: false
        };
    }

    async componentDidMount() {
        let deposits = [];
        let deposit_return_token_exists = false;
        try {
            deposits = await request('/deposits', 'GET', null);
            deposits = deposits ? deposits : [];


            let token_exists = await getTokenExists({permission: 'deposit_return'});
            if (!token_exists.error) {
                deposit_return_token_exists = token_exists?.exists ? true : false;
            }

        } catch(e) {
            deposits = [];
            deposit_return_token_exists = false;
        }
        
        this.setState({
            deposits,
            deposit_return_token_exists
        });
    }

    validateCreateForm = async (values) => {
        const errors = {};
        const deposit_name = values.name;
        const found = this.state.deposits.find((deposit) => deposit.name === deposit_name);
        if(found) {
            errors.name = translate('custom.this_name_is_already_taken');
        }
        return errors
    };

    render() {

        return (
            <Create title={translate('custom.create_deposit')} {...this.props}>
                <SimpleForm variant="standard" warnWhenUnsavedChanges validate={this.validateCreateForm}
                    toolbar={<SaveAndAddNewToolbarButtons />}
                >

                    {deposit_form(this.state.deposit_return_token_exists)}

                </SimpleForm>
            </Create>
        )
    }
}