import react from 'react';
import Devices from './Devices';
import { Route } from 'react-router-dom';
import { AppBar, Box, Button, CssBaseline, Divider, Drawer, Grid, Hidden, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import NfcIcon from '@mui/icons-material/Nfc';
import StorageIcon from '@mui/icons-material/Storage';
import SystemSecurityUpdateIcon from '@mui/icons-material/SystemSecurityUpdate';
import RouterIcon from '@mui/icons-material/Router';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import TokenIcon from '@mui/icons-material/Token';
import { translate } from "../../i18n/customI18nProvider";
import config from '../../config';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const drawerWidth = 240;

const menu_items = [
    {
        key: 'events',
        name: translate('custom.events'),
        path: '/admin#/events',
        icon: <EventIcon />,
        minRight: config.permissions.ADMIN.value
    },
    {
        key: 'users',
        name: translate('custom.users'),
        path: '/v2/admin/users',
        icon: <PeopleIcon />,
        minRight: config.permissions.ADMIN.value
    },
    {
        key: 'clients',
        name: translate('custom.clients'),
        path: '/admin#/clients',
        icon: <NfcIcon />,
        minRight: config.permissions.ADMIN.value
    },
    {
        key: 'replica_sets',
        name: translate('custom.replica_sets'),
        path: '/admin#/replica_sets',
        icon: <StorageIcon />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'latest_app',
        name: translate('custom.latest_app'),
        path: '/admin#/latest_app',
        icon: <SystemSecurityUpdateIcon />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'devices',
        name: translate('custom.devices'),
        path: '/v2/admin/devices',
        icon: <RouterIcon />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'tickets',
        name: translate('custom.tickets'),
        path: '/v2/admin/tickets',
        icon: <LocalActivityIcon />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'tokens',
        name: translate('custom.tokens'),
        path: '/v2/admin/tokens',
        icon: <TokenIcon />,
        minRight: config.permissions.SUPERADMIN.value
    }
]

const styles = {
    highlightedMenu: {
        backgroundColor: '#e0e0e0'
    },
    menu: {
        
    }
}

// Define your custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: config.baseColor, // Replace with your desired primary color
        },
        secondary: {
            main: '#00ff00', // Replace with your desired secondary color
        },
    },
});

const drawer = (right, highlighted=null) => (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menu_items.map((item) => (
            (right >= item.minRight) ? (
                <Link
                    key={item.key}
                    to={item.path}
                    style={{textDecoration: 'none', color: 'inherit'}}
                >
                    <ListItem
                        key={item.key}
                        disablePadding
                        style={(highlighted === item.key) ? styles.highlightedMenu : styles.menu}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                </Link>
            ) : null
        ))}
      </List>
    </div>
);

class V2Layout extends react.Component {

    constructor(props) {
        super(props);

        let currentMenu = this.props.currentMenu;

        for (let i = 0; i < menu_items.length; i++) {
            if (menu_items[i].key === currentMenu) {
                currentMenu = menu_items[i];
                break;
            }
        }

        this.state = {
            mobileOpen: false,
            isClosing: false,
            right: localStorage.getItem('right'),
            currentMenu
        };
    }

    handleDrawerClose = () => {
        this.setState({isClosing: true, mobileOpen: false});
    };

    handleDrawerTransitionEnd = () => {
        this.setState({isClosing: false});
    };

    handleDrawerToggle = () => {
        if (!this.state.isClosing) {
            this.setState({mobileOpen: !this.state.mobileOpen});
        }
    };

    render() {

        let title = this.props.title ? this.props.title : null;

        return (
            <ThemeProvider theme={theme}>
            <Grid container style={{ display: 'flex' }}>
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        backgroundColor: config.baseColor,
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            {title ? title : this.state.currentMenu?.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid item xs={12} sm={drawerWidth} style={{ flexShrink: 0 }}>
                    <Drawer
                        variant="temporary"
                        open={this.state.mobileOpen}
                        onTransitionEnd={this.handleDrawerTransitionEnd}
                        onClose={this.handleDrawerClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        PaperProps={{
                            style: {
                                backgroundColor: config.backgroundColor
                            }
                        }}
                    >
                        {drawer(this.state.right, this.state.currentMenu?.key)}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                        PaperProps={{
                            style: {
                                backgroundColor: config.backgroundColor
                            }
                        }}
                    >
                        {drawer(this.state.right, this.state.currentMenu?.key)}
                    </Drawer>
                </Grid>
                <Grid item xs={12}
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        padding: '16px'
                    }}
                >
                    <Toolbar />
                    {this.props.children}
                </Grid>
            </Grid>
            </ThemeProvider>
        )
    }
}

export default V2Layout;
