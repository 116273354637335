import { Button, Chip, Grid } from "@mui/material";
import { translate } from "../../i18n/customI18nProvider";
import { ChipField, Datagrid, FunctionField, List, NumberField, ReferenceField, ResourceContextProvider, TextField } from "react-admin";
import React from "react";
import config from "../../config";
import { convertMessageCodeToMessage, downloadFileFromResponse, getRequestOptions } from "../../util";

const downloadSmartphoneEntranceChecks = async (smartphoneNumber) => {
    let response = await fetch(config.apiUrl + '/smartphones/' + smartphoneNumber + '/entrance_checks/export', getRequestOptions('GET'));
    if(response.status !== 200) {
        let responseJson = await response.json();
        Swal.fire({
            title: translate('custom.error'),
            text: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
            icon: 'error',
            confirmButtonText: translate('custom.ok'),
            confirmButtonColor: config.baseColor
        });
        return;
    } else {
        await downloadFileFromResponse(response, translate('custom.smartphone') + ' - ' + translate('custom.entrance_checks') + ' - ' + smartphoneNumber + '.xlsx', false);
    }
}

const EntranceChecksTable = (props) => {

    let currentPath = window.location.href;
    currentPath = currentPath.split('#')[1];
    let smartphoneNumber = currentPath.split('/')[2];
    
    return (
        <>

            <Grid item xs={12} style={{textAlign: 'right'}}>
                <Button
                    onClick={() => {
                        downloadSmartphoneEntranceChecks(smartphoneNumber)
                    }}
                    variant="outlined"
                    style={{borderColor: config.baseColor, color: config.baseColor}}
                >
                    {translate('custom.export')}
                </Button>
            </Grid>

            <Grid item xs={12}>
            <ResourceContextProvider value={"entrance_checks"}>
                <List
                    actions={null}
                    // syncWithLocation
                    basePath={"/entrance_checks"}
                    filter={{smartphone_number: smartphoneNumber}}
                    bulkActionButtons={false}
                    title={' '}
                >
                    <Datagrid
                        bulkActionButtons={false}
                    >
                        <FunctionField
                            label={translate('custom.transaction_type')}
                            render={record => translate(`custom.${record.type}`)}
                        />
                        <TextField label={translate('custom.date')} source="date" />
                        <ReferenceField
                            label={translate('custom.bar')}
                            reference="bars"
                            source="bar_id"
                            sortable={false}
                            link={(record, reference) => `/bars/${record.bar_id}`}
                        >
                            <ChipField source="name" />
                        </ReferenceField>
                        <NumberField
                            label={translate('custom.amount')}
                            source="amount"
                            locales={translate('custom.OPTIONS.i18n_number_format')}
                            options={{ style: 'decimal', minimumFractionDigits: 2 }}
                        />
                        <FunctionField
                            label={translate('custom.client')}
                            render={record => {
                                return (
                                    <Chip
                                        label={record.client_uid}
                                        onClick={() => {
                                            window.open(`/admin#/clients/${record.client_uid}`, '_blank');
                                        }}
                                    />
                                )
                            }}
                        />
                    </Datagrid>
                </List>
            </ResourceContextProvider>
            </Grid>
        </>
    )
}

class SmartphoneEntranceChecksTable extends React.Component {

    render() {
        return (
            <Grid container spacing={2}>
                <EntranceChecksTable />
            </Grid>
        )
    }

}

export default SmartphoneEntranceChecksTable;
