import React from 'react';
import {
    ReferenceManyField,
    ChipField,
    SingleFieldList,
    ArrayInput,
    SelectInput,
    NumberInput,
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    required,
    SimpleList,
    Filter,
    Pagination,
    CloneButton,
    SimpleFormIterator,
    Toolbar,
    SaveButton,
    FormDataConsumer,
    useRefresh,
    BooleanInput,
    AutocompleteArrayInput,
    NumberField
} from 'react-admin';
import LocalBar from '@material-ui/icons/LocalBar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RestoreIcon from '@material-ui/icons/Restore';
import Actions from '../components/reactAdmin/Actions';
import { useMediaQuery } from '@material-ui/core';
import {dateFromObjectId, formatCurrency, formatCurrencyWithCode, getChoices} from '../util';
// import OrderedFormIterator from '../iterator/OrdererdFormIterator';
import config from "../config";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import { request } from '../util';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import { validateNumber, validatePositiveNumberNonRequired, validateNumberWithMax } from '../validators/number_validators';
import { translate } from '../i18n/customI18nProvider';

import './../css/App.css';
import ImportConsumptions from '../components/bar/ImportConsumptions';
import ExportConsumptions from '../components/bar/ExportConsumptions';
import ImportBars from '../components/bar/ImportBars';
import { getRight, getToken } from '../services/storage';
import { testPrint } from '../services/print.service';


export const BarIcon = LocalBar;


// Styles

const headerTextStyles = {
    width: '100%',
    borderBottom: '2px solid' + config.baseColor,
    // marginBottom: '10px',
    color: config.baseColor
};

// Styles End



let tempStorage = {};

const QuickFilter = ({ label }) => {
    return <Chip style={{margin: 8, marginLeft: 0}} label={translate(label)} />;
};

const BarListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" variant='outlined' alwaysOn />
        <QuickFilter source="deleted_only" label={'custom.deleted_only'} defaultValue={true} />
        <QuickFilter source="with_deleted" label={'custom.with_deleted'} defaultValue={true} />
    </Filter>
);

const BarListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const StatisticsButton = ({record}) => {
    return (
        <Button variant="outlined" color="primary" onClick={() => {
            window.open(`/statistics/${record._id}`, '_blank');
        }}>
            <EqualizerIcon style={{marginRight: '8px'}} />
            {translate('custom.statistics')}
        </Button>
    );
};

const CustomButtons = ({record}) => {
    const refresh = useRefresh();
    if(record.deleted) {
        return (
            <>
                <Button
                    color="primary"
                    startIcon={<RestoreIcon />}
                    onClick={async () => {
                        try {
                            await request('/bars/' + record._id + '/restore', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{marginLeft: '10px', marginRight: '10px'}}
                >
                    {translate('custom.restore')}
                </Button>
            </>
        )
    } else {
        return (
            <>
                <EditButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
                <CloneButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
                <StatisticsButton record={record} />
            </>
        )
    }
}

export const BarList = (props) => {

    let users_view;
    let opt;
    if(getToken() && (getRight()  >= config.permissions.ADMIN.value))
        users_view = (
            <ReferenceManyField label={translate('custom.users')} reference="users" target="bar_id" sortable={false} >
                <SingleFieldList>
                    <ChipField source="email" />
                </SingleFieldList>
            </ReferenceManyField>
        )
    else
        users_view = (
            <></>
        )

    if(getToken() && (getRight()  >= config.permissions.ADMIN.value)) {
        opt = {
            ...props
        }
    }
    else {
        opt = {
            ...props,
            hasCreate: false,
            bulkActionButtons: false
        }
    }

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // let isSmall = false;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
    //     isSmall = true;
    // }
    return (

        <>

        <ImportBars />
        
        <List {...opt}
            actions={<Actions />}
            filters={<BarListFilter />}
            pagination={<BarListPagination />}
            perPage={100}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => (dateFromObjectId(record._id)).toLocaleDateString('fr-CH')}
                    tertiaryText={record => (
                        <ReferenceManyField basePath="users" record={record} target="bar_id" reference="users" linkType={false}>
                            <SingleFieldList>
                                <ChipField source="email" />
                            </SingleFieldList>
                        </ReferenceManyField>
                    )}
                />
            ) : (
                <Datagrid>
                    <TextField label={translate('custom.name')} source="name" />
                    {users_view}
                    <NumberField label={translate('custom.desired_number_of_smartphones')} source="number_of_smartphones" />
                    <CustomButtons />
                </Datagrid>
            )}
        </List>

        </>
    );
};

const PostTitle = ({ record }) => {
    return <span>{translate('custom.bar')} {record ? `"${record.name}"` : ''}</span>;
};

const ColorIconRender = ({ record }) => {
    return (
        <>
            <Button
                startIcon={<FiberManualRecordIcon style={{color: record.colorCode}} />}
                disabled={true}
                size="small"
                style={{color: 'black'}}
            >
                {translate('custom.colors.' + record.id)}
            </Button>
        </>
    );
};

const DepositTextRender = ({ record, currencyCode }) => {
    return (
        <>
            <Button
                disabled={true}
                size="small"
                style={{color: 'black', textTransform: 'none'}}
            >{record.name + ' ' + translate('custom.of') + ' ' + (currencyCode ? (formatCurrencyWithCode(record.price, currencyCode)) : (formatCurrency(record.price)))}</Button>
        </>
    );
}

const possible_deposit_returns_form = (deposits=[]) => {

    return (

        <Grid container style={{width: '100%'}} spacing={2}>
            <Grid item xs={12} style={{margin: '10px', border: '1px solid lightgray', borderRadius: '10px'}}>
                
                <div style={{width: '100%'}}>
                    <h3 style={headerTextStyles}>{translate('custom.possible_deposit_returns')}</h3>
                </div>

                <ArrayInput source="possible_deposit_returns" label={''}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {

                                return (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <SelectInput
                                                validate={required()}
                                                source={getSource("_id")} label={translate('custom.name')}
                                                choices={deposits}
                                                resettable={true}
                                                style={{"width": "100%"}}
                                                record={scopedFormData}
                                                variant="standard"
                                                // change name to lower case before displaying
                                                optionText={(choice) => (translate('custom.return') + ' ' + choice.name.toLowerCase())}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <NumberInput
                                                source={getSource("price")} label={translate('custom.price')}
                                                style={{"width": "100%"}}
                                                record={scopedFormData}
                                                variant="standard"
                                                onWheel={(e) => e.target.blur()}
                                                disabled
                                                // helperText={translate('custom.TEXT.save_form_to_see_updated_value_if_deposit_is_changed')}
                                                // multiply by -1 to get the negative value
                                                format={(v) => v * -1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <NumberInput
                                                source={getSource("delta")} label={translate('custom.delta')}
                                                style={{"width": "100%"}}
                                                record={scopedFormData}
                                                variant="standard"
                                                onWheel={(e) => e.target.blur()}
                                                disabled
                                                // helperText={translate('custom.TEXT.save_form_to_see_updated_value_if_deposit_is_changed')}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
        </Grid>
    );
}

const possible_deposits_form = (deposits=[]) => {

    deposits = deposits.map((deposit) => {
        return {
            id: deposit._id,
            ...deposit
        }
    });

    return (

        <Grid container style={{width: '100%'}} spacing={2}>
            <Grid item xs={12} style={{margin: '10px', border: '1px solid lightgray', borderRadius: '10px'}}>

                <div style={{width: '100%'}}>
                    <h3 style={headerTextStyles}>{translate('custom.possible_deposits')}</h3>
                </div>

                <ArrayInput source="possible_deposits" label={''}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {

                                return (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <SelectInput
                                                source={getSource("_id")} label={translate('custom.name')}
                                                choices={deposits}
                                                resettable={true}
                                                style={{"width": "100%"}}
                                                record={scopedFormData}
                                                variant="standard"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <NumberInput
                                                source={getSource("price")} label={translate('custom.price')}
                                                style={{"width": "100%"}}
                                                record={scopedFormData}
                                                variant="standard"
                                                onWheel={(e) => e.target.blur()}
                                                disabled
                                                // helperText={translate('custom.TEXT.save_form_to_see_updated_value_if_deposit_is_changed')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <NumberInput
                                                source={getSource("delta")} label={translate('custom.delta')}
                                                style={{"width": "100%"}}
                                                record={scopedFormData}
                                                variant="standard"
                                                onWheel={(e) => e.target.blur()}
                                                disabled
                                                // helperText={translate('custom.TEXT.save_form_to_see_updated_value_if_deposit_is_changed')}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>

            </Grid>
        </Grid>
    );
}

const consumption_form = (deposits=[], max_consumption_price = 0, currencyCode='', bar_id=null) => {
    return (

        <Grid container style={{width: '100%'}} spacing={2}>
            <Grid item xs={12} style={{margin: '10px', border: '1px solid lightgray', borderRadius: '10px'}}>

                <div style={{width: '100%'}}>
                    <h3 style={headerTextStyles}>{translate('custom.consumptions')}</h3>
                </div>

                {(bar_id) ? (
                    <ExportConsumptions
                        bar_id={bar_id}
                    />
                ) : null}

                <ArrayInput source="consumptions" label={' '}>
                    <SimpleFormIterator>

                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                                return (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                                <TextInput
                                                    source={getSource("name")} label={translate('custom.name')} validate={required()}
                                                    autoFocus={(scopedFormData && scopedFormData?.index == 0) || !scopedFormData}
                                                    style={{"width": "100%", "fontWeight" : "bold"}}
                                                    className="bigInput"
                                                    record={scopedFormData}
                                                    variant="standard"
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            if(!scopedFormData?.name) {
                                                                scopedFormData.name = translate('custom.other');
                                                                scopedFormData.price = 1;
                                                                scopedFormData.type = config.consumption_types.INPUT;
                                                                scopedFormData.color = 'gray';
                                                                let add_button = document.getElementsByClassName('button-add')[2];
                                                                add_button.click();
                                                            } else if(scopedFormData.name && 'price' in scopedFormData) {
                                                                let add_button = document.getElementsByClassName('button-add')[2];
                                                                add_button.click();
                                                            }
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if ((e.keyCode == 8) || (e.charCode == 8)) {
                                                            if(!scopedFormData?.name && !('price' in scopedFormData)) {
                                                                let delete_button = document.getElementsByClassName('button-remove');
                                                                if(delete_button.length > 0) {
                                                                    delete_button[delete_button.length - 1].click();
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                                <NumberInput
                                                    source={getSource("price")} label={translate('custom.price')} step={1} validate={max_consumption_price ? validateNumberWithMax(max_consumption_price) : validateNumber}
                                                    style={{"width": "100%"}}
                                                    className="bigInput"
                                                    record={scopedFormData}
                                                    variant="standard"
                                                    onWheel={(e) => e.target.blur()}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            if(scopedFormData.name && 'price' in scopedFormData) {
                                                                let add_button = document.getElementsByClassName('button-add')[2];
                                                                add_button.click();
                                                            }
                                                        }
                                                    }}
                                                    // end text
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{currencyCode}</InputAdornment>,
                                                    }}
                                                    // helper text
                                                    helperText={translate('custom.TEXT.price_is_excluding_deposit_amount_if_any')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                                <SelectInput
                                                    resettable={true} label={translate('custom.minimum_age')} source={getSource("min_age")} choices={getChoices(config.ages)}
                                                    style={{"width": "100%"}}
                                                    record={scopedFormData}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                                <SelectInput
                                                    source={getSource("color")} label={translate('custom.color')}
                                                    choices={config.consumption_colors}
                                                    resettable={true}
                                                    optionText={<ColorIconRender />}
                                                    style={{"width": "100%"}}
                                                    record={scopedFormData}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                                <SelectInput
                                                    source={getSource("type")} label={translate('custom.item_type')} validate={required()}
                                                    choices={[
                                                        {id: 'quantity', name: translate('custom.unit_quantity')},
                                                        {id: 'input', name: translate('custom.quantity_to_be_entered')}
                                                    ]}
                                                    defaultValue='quantity' 
                                                    style={{"width": "100%"}}
                                                    record={scopedFormData}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            {scopedFormData?.type == 'input' && (
                                                <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                                    <SelectInput fullWidth={true} source={getSource("round")} label={translate('custom.round')} choices={getChoices(config.round, 'currency', translate('custom.cents'), currencyCode)} variant="standard" />
                                                </Grid>
                                            )}
                                            {scopedFormData?.type == 'quantity' && (
                                                <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                                                    <SelectInput
                                                        source={getSource("deposit")} label={translate('custom.deposit')}
                                                        choices={deposits}
                                                        resettable={true}
                                                        optionText={<DepositTextRender currencyCode={currencyCode} />}
                                                        style={{"width": "100%"}}
                                                        record={scopedFormData}
                                                        variant="standard"
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                                                <BooleanInput
                                                    source={getSource("hidden")} label={translate('custom.hidden')}
                                                    defaultValue={false}
                                                    style={{"width": "100%"}}
                                                    record={scopedFormData}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                                                <BooleanInput
                                                    source={getSource("can_buy_only_with_voucher")} label={translate('custom.can_buy_only_with_voucher')}
                                                    defaultValue={false}
                                                    style={{"width": "100%"}}
                                                    record={scopedFormData}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                                <TextInput
                                                    source={getSource("tag")} label={translate('custom.tag')}
                                                    style={{"width": "100%"}}
                                                    record={scopedFormData}
                                                    variant="standard"
                                                />
                                            </Grid>
                                        </Grid>
                                    </>

                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
        </Grid>
    );
}

const printers_form = () => {
    return (

        <Grid container style={{width: '100%'}} spacing={2}>
            <Grid item xs={12} style={{margin: '10px', border: '1px solid lightgray', borderRadius: '10px'}}>

                <div style={{width: '100%'}}>
                    <h3 style={headerTextStyles}>{translate('custom.printers')}</h3>
                </div>


                <ArrayInput source="printers" label={' '}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                                return (
                                    <>
                                        <Grid container spacing={2} style={{width: '100%'}}>
                                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                                <SelectInput fullWidth source={getSource("type")} label={translate('custom.printer_type')} validate={required()} autoFocus variant='outlined' choices={config.printer_types} record={scopedFormData} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                                <TextInput fullWidth source={getSource("server_id")} label={translate('custom.server_id')} validate={required()} variant='outlined' record={scopedFormData} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                                <TextInput fullWidth source={getSource("ip")} label={translate('custom.ip_address')} validate={required()} variant='outlined' record={scopedFormData} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                                <BooleanInput fullWidth source={getSource("master")} label={translate('custom.master')} defaultValue={true} record={scopedFormData}
                                                    helperText={translate('custom.TEXT.master_printer_info_text')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                                <Button
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{marginTop: '8px'}}
                                                    onClick={async () => {
                                                        let payload = {
                                                            type: scopedFormData.type,
                                                            server_id: scopedFormData.server_id,
                                                            ip: scopedFormData.ip
                                                        };

                                                        testPrint(payload);
                                                    }}
                                                >
                                                    {translate('custom.test_print')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <ArrayInput source={getSource("tags")} label={translate('custom.tags')}>
                                                    <SimpleFormIterator>
                                                        <TextInput fullWidth label={translate('custom.tag')} validate={required()} variant='outlined' record={scopedFormData} />
                                                    </SimpleFormIterator>
                                                </ArrayInput>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>


            </Grid>
        </Grid>

    );
}

class BarDetailsForm extends React.Component {

    constructor(props) {
        super(props);

        let table_numbers = props.record?.table_numbers || [];
        table_numbers = table_numbers.map((table_number) => {
            return {id: table_number, name: table_number};
        })

        this.state = {
            table_numbers: table_numbers,
            deposits: []
        }
    }

    async componentDidMount() {
        let deposits = await request('/deposits', 'GET', null);
        if(deposits.error) {
            alert(deposits.error);
        } else {
            this.setState({deposits: deposits});
        }
    }


    render() {

        return (
            <Grid container spacing={2} style={{width: '100%'}}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth source="name" label={translate('custom.name')} validate={required()} autoFocus variant='outlined'
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                let add_button = document.getElementsByClassName('button-add')[0];
                                add_button.click();
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <NumberInput fullWidth source="number_of_smartphones" label={translate('custom.desired_number_of_smartphones')} variant='outlined'
                        // disable scroll
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <TextInput fullWidth source="order_number_prefix" label={translate('custom.order_number_prefix')} variant='outlined' defaultValue={''} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <BooleanInput fullWidth source="enable_table_service" label={translate('custom.enable_table_service')} defaultValue={false} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <AutocompleteArrayInput fullWidth source="table_numbers" label={translate('custom.table_numbers')} choices={this.state.table_numbers} style={{margin: '10px'}} variant='outlined'
                        defaultHighlightedIndex={0}
                        onCreate={(text) => {
                            if(text === "")
                                text = prompt(translate('custom.enter_a_new_one_to_create'));
                            const newTag = { id: text, name: text };
                            let table_numbers = this.state.table_numbers;
                            table_numbers.push(newTag);
                            this.setState({table_numbers: table_numbers});
                            return newTag;
                        }}
                        suggestionLimit={5}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextInput fullWidth source="info_text" label={translate('custom.additional_info_text')} variant='outlined'
                        multiline={true}
                        // rows
                        InputProps={{
                            rows: 4
                        }}
                    />
                </Grid>
            </Grid>
        )

    }

}

const validateBarsForm = async (values) => {

    let errors = {};

    let bars;
    try {
        if (tempStorage['bars']) {
            bars = tempStorage['bars'];
        } else {
            bars = await request('/bars', 'GET', null);
            tempStorage['bars'] = bars;
        }
    } catch (e) {
        console.log(e.message)
    }

    if(('_id' in values || 'id' in values) && 'name' in values) {
        // Updating existing bar
        bars.find((bar) => {
            if(bar.name === values.name && (bar._id !== values._id || bar.id !== values.id)) {
                errors['name'] = translate('custom.this_name_is_already_taken');
            }
        })
    } else if ('name' in values) {
        // New Bar Creation
        bars.find((bar) => {
            if(bar.name === values.name) {
                errors['name'] = translate('custom.this_name_is_already_taken');
            }
        })
    }

    if(values['consumptions']) {
        for(let i=0; i<values['consumptions'].length; i++) {
            const consumption = values['consumptions'][i];
            let filtered = values['consumptions'].filter(c => c?.name === consumption?.name);
            if(filtered.length > 1) {
                if(!('consumptions' in errors)) {
                    errors['consumptions'] = [];
                }
                if(!(errors['consumptions'][i])) {
                    errors['consumptions'][i] = {};
                }
                errors['consumptions'][i]['name'] = translate('custom.this_name_is_already_taken');
            }
        }
    }

    return errors
}


export class BarEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            max_consumption_price: 0,
            currencyCode: '',
            deposits: []
        }
    }

    async componentDidMount() {

        tempStorage = {};

        let general_information = await request('/events/general', 'GET', null);
        if(general_information.error) {
            alert(general_information.error_code ? translate('custom.' + general_information.error_code) : general_information.error);
        } else {
            this.setState({max_consumption_price: general_information.maxConsumptionPrice, currencyCode: general_information.currencyCode});
        }

        let deposits = await request('/deposits', 'GET', null);
        if(deposits.error) {
            alert(deposits.error);
        } else {
            this.setState({deposits: deposits});
        }
    }

    render() {
        return (
            <Edit mutationMode={'undoable'} title={<PostTitle />} {...this.props}>
                <SimpleForm variant="standard" redirect={'edit'} warnWhenUnsavedChanges validate={validateBarsForm} submitOnEnter={false}>
                    
                    <BarDetailsForm />

                    <ImportConsumptions
                        bar_id={this.props.id}
                    />

                    {/* hr */}
                    <div style={{width: '100%', height: '1px', backgroundColor: 'lightgray', marginTop: '20px', marginBottom: '10px'}}></div>

                    {possible_deposit_returns_form(this.state.deposits)}

                    {possible_deposits_form(this.state.deposits)}

                    {consumption_form(this.state.deposits, this.state.max_consumption_price, this.state.currencyCode, this.props.id)}

                    {printers_form()}

                </SimpleForm>
            </Edit>
        );
    }
}

const SaveAndAddNewToolbar = (props) => {
    const refresh = useRefresh();
    return (
        <Toolbar>
            <SaveButton
                {...props}
                label="ra.action.save"
                redirect="edit"
                style={{marginRight: 10}}
            />
            <SaveButton
                {...props}
                label={translate('custom.save_and_add')}
                redirect={() => {
                    refresh();
                    return 'create';
                }}
            />
        </Toolbar>
    )
}

export class BarCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            max_consumption_price: 0,
            currencyCode: ''
        }
    }

    async componentDidMount() {

        tempStorage = {};

        let general_information = await request('/events/general', 'GET', null);
        if(general_information.error) {
            alert(general_information.error_code ? translate('custom.' + general_information.error_code) : general_information.error);
        } else {
            this.setState({max_consumption_price: general_information.maxConsumptionPrice, currencyCode: general_information.currencyCode});
        }

        let deposits = await request('/deposits', 'GET', null);
        if(deposits.error) {
            alert(deposits.error);
        } else {
            this.setState({deposits: deposits});
        }
    }

    render() {
        let bar_create_view;
        if(getToken() && (getRight()  >= config.permissions.ADMIN.value))
            bar_create_view = (
                <Create title={translate('custom.create_bar')} {...this.props}>
                    <SimpleForm variant="standard" warnWhenUnsavedChanges validate={validateBarsForm}
                        toolbar={<SaveAndAddNewToolbar />}
                        submitOnEnter={false}
                    >
                        
                        <BarDetailsForm />

                        {possible_deposit_returns_form(this.state.deposits)}

                        {possible_deposits_form(this.state.deposits)}
                        
                        {consumption_form(this.state.deposits, this.state.max_consumption_price, this.state.currencyCode)}

                        {printers_form()}
                    
                    </SimpleForm>
                </Create>
            )
        else
            bar_create_view = (
                <></>
            )

        return (bar_create_view)
    }
}
