import React, { Component } from 'react';
import '../css/App.css';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ActionDebit from '@material-ui/icons/LocalBar';
import ActionCredit from '@material-ui/icons/LocalAtm';
import ActionTwint from '@material-ui/icons/CreditCard';
import ActionUnpaid from '@material-ui/icons/Lock';
import ActionEntrance from '@material-ui/icons/LocalActivity';
import ActionZone from '@material-ui/icons/Toll';
import ActionCheckout from '@material-ui/icons/ExitToApp';
import { withStyles, withTheme } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { nativeTranslate as translate } from "../i18n/customI18nProvider";
import Grid from '@material-ui/core/Grid';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CreditedIcon from '@material-ui/icons/Add';
import DebitedIcon from '@material-ui/icons/Remove';
import RefundedIcon from '@material-ui/icons/CallMissed';
import DonationIcon from '@material-ui/icons/Redeem';
import { Link } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MoveDownIcon from '@mui/icons-material/MoveDown';

import {formatCurrencySign, convertMessageCodeToMessage, request, formatCurrency} from "../util";


import 'whatwg-fetch';

import config from '../config';
import { Hidden, Typography } from '@material-ui/core';
import { setTheme } from '../services/storage';

const customStyles = {
    glassBackgroundMain: {
        background: 'rgba(255, 255, 255, 0.46)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        borderRadius: '20px'
    },
    glassBackgroundMainDark: {
        background: '#181a1b',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(20px)',
        padding: '20px',
        borderRadius: '20px',
        borderColor: 'gray',
        borderWidth: '1px',
        borderStyle: 'solid',
    },
    mainBackground: {
        background: 'white',
        padding: '20px',
        width: '100%',
        minHeight: '100vh'
    },
    mainBackgroundDark: {
        background: '#181a1b',
        padding: '20px',
        width: '100%',
        color: 'white',
        minHeight: '100vh'
    },
    subBackground: {
        background: 'white',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        border: '1px solid gray',
        borderRadius: '10px'
    },
    subBackgroundDark: {
        background: '#181a1b',
        boxShadow: '0 4px 30px rgba(256, 256, 256, 0.1)',
        padding: '20px',
        color: 'white',
        border: '1px solid gray',
        borderRadius: '10px'
    },
    voucherLeft: {
        padding: '10px',
        backgroundColor: 'white',
        color: '#060',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '5px',
        textTransform: 'none',
        fontSize: '20px',
        border: '1px solid gray',
        borderRight: '1px dashed darkgrey',
        borderColor: 'gray',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
    },
    voucherLeftDark: {
        padding: '10px',
        backgroundColor: 'white',
        color: '#060',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '5px',
        textTransform: 'none',
        fontSize: '20px',
        borderRight: '1px dashed darkgrey',
        borderColor: 'darkgray',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
    },
    voucherRight: {
        padding: '10px',
        backgroundColor: 'white',
        color: 'black',
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '5px',
        textTransform: 'none',
        fontSize: '20px',
        border: '1px solid gray',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        minWidth: '150px'
    },
    voucherRightDark: {
        padding: '10px',
        backgroundColor: 'white',
        color: 'black',
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '5px',
        textTransform: 'none',
        fontSize: '20px',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        minWidth: '150px'
    },
    iconStyle: {
        fontSize: '30px',
        padding: '5px',
        backgroundColor :'white',
        borderRadius: '5px'
    }
}

const ICONS = {
    'credit' : {
        'text' : translate('custom.recharge'),
        'color' : config.successColor,
        'colorDark' : config.successColorDark,
        'icon' : <ActionCredit style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_cash' : {
        'text' : translate('custom.recharge'),
        'color' : config.successColor,
        'colorDark' : config.successColorDark,
        'icon' : <ActionCredit style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_twint' : {
        'text' : translate('custom.recharge_twint'),
        'color' : config.successColor,
        'colorDark' : config.successColorDark,
        'icon' : <ActionTwint style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_card' : {
        'text' : translate('custom.recharge_card'),
        'color' : config.successColor,
        'colorDark' : config.successColorDark,
        'icon' : <ActionTwint style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'credit_wristband' : {
        'text' : translate('custom.balance_transfer'),
        'color' : "#060",
        'icon' : <MoveDownIcon style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'unpaid' : {
        'text' : translate('custom.recharge_non_refundable'),
        'color' : config.successColor,
        'colorDark' : config.successColorDark,
        'icon' : <ActionUnpaid style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'unpaid_cash' : {
        'text' : translate('custom.recharge_non_refundable'),
        'color' : config.successColor,
        'colorDark' : config.successColorDark,
        'icon' : <ActionUnpaid style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'debit': {
        'text' : translate('custom.consumption'),
        'color' : config.baseColor,
        'colorDark' : config.baseColorDark,
        'icon' : <ActionDebit style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'entrance': {
        'text' : translate('custom.entrance'),
        'color' : config.baseColor,
        'colorDark' : config.baseColorDark,
        'icon' : <ActionEntrance style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'zone': {
        'text' : translate('custom.zone'),
        'color' : config.baseColor,
        'colorDark' : config.baseColorDark,
        'icon' : <ActionZone style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'checkout': {
        'text' : translate('custom.refund'),
        'color' : config.dangerColor,
        'colorDark' : config.dangerColorDark,
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'voucher' : {
        'text' : translate('custom.voucher'),
        'color' : config.successColor,
        'colorDark' : config.successColorDark,
        'icon' : <ConfirmationNumberIcon style={{color:"#060", ...customStyles.iconStyle}} />,
        'type' : 'credited'
    },
    'refund' : {
        'text' : translate('custom.refund'),
        'color' : config.dangerColor,
        'colorDark' : config.dangerColorDark,
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'forced_refund' : {
        'text' : translate('custom.forced_refund'),
        'color' : config.dangerColor,
        'colorDark' : config.dangerColorDark,
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'form_refund' : {
        'text' : translate('custom.form_refund'),
        'color' : config.dangerColor,
        'colorDark' : config.dangerColorDark,
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'twint_refund' : {
        'text' : translate('custom.twint_refund'),
        'color' : config.dangerColor,
        'colorDark' : config.dangerColorDark,
        'icon' : <ActionCheckout style={{color:"#c00", ...customStyles.iconStyle}} />,
        'type' : 'refunded'
    },
    'donation' : {
        'text' : translate('custom.donation'),
        'color' : config.baseColor,
        'colorDark' : config.baseColorDark,
        'icon' : <DonationIcon style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
    'balance_transfer_from_wristband' : {
        'text' : translate('custom.balance_transfer'),
        'color' : config.baseColor,
        'colorDark' : config.baseColorDark,
        'icon' : <MoveUpIcon style={{color:"#08607e", ...customStyles.iconStyle}} />,
        'type' : 'debited'
    },
};

const canCollapse = transaction => transaction.type === config.transaction_types.DEBIT;


class ClientEmbed extends Component {

    constructor(){
        super();
        this.state = {
            client_found: false,
            age: null,
            uid: '',
            short_id: '',
            name: null,
            balance: '',
            unpaid: 0,
            loading: true,
            transactions: [],
            vouchers: [],
            vouchers_names: [],
            animationCount: 0,
            error: false,
            error_code: ''
        }
        this.currency = '';
    }

    getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    componentDidMount = async () => {
        try {

            this.interval = null;

            let close = this.getUrlParameter('close');
            if(close === 'true') {
                window.close();
                return;
            }

            this.setState({loading: true, error: false});

            this.client = this.props.match.params.client;
            let response = await request("/clients/short/" + this.client, 'GET');

            if(response.error) {
                if (response.error_code == '|event_is_archived|' || response.error_code == '|wallets_are_not_open_yet|') {
                    await this.setState({loading: false, error: true, error_code: response.error_code});
                    return;
                } else if (response.error_code === "|client_not_found|") {
                    // The entered value might be ticket id. Trying to get the client from ticket_id
                    response = await request('/tickets/'+this.client, 'GET');
                    if(response.error) {
                        this.setState({loading: false, error: true, client_found: false});
                        return;
                    }
                    // the client is created from ticket_id, fetch the client from the ticket_id
                    response = await request("/clients/short/" + this.client, 'GET');
                } else {
                    this.setState({loading: false, error: true, client_found: false});
                    return;
                }
            }
            
            let client_uid = response.uid;
            let general_information = await request(`/events/general?client_uid=${client_uid}`, 'GET');
            if (general_information.error) {
                alert(general_information.error);
                return;
            }
            
            if(response.vouchers) {
                response.vouchers = response.vouchers.filter((voc) => {
                    if(voc.quantity)
                        return voc
                })
            }

            await this.setState({
                uid: client_uid,
                short_id: response.short_id,
                general_information,
                name: response.name,
                balance: response.balance,
                transactions: response.transactions,
                vouchers: response.vouchers,
                vouchers_names: response.vouchers_names,
                unpaid: response.split_balance?.unpaid,
                loading: false,
                spent: response.split_balance?.spent,
                age: response.age,
                client_found: true
            });

            if (!this.interval) {
                // interval to call refresh data every 5 seconds
                this.interval = setInterval(this.refreshData, 5000);
            }

        } catch (error){
            console.log(error.message);
            setTimeout(this.componentDidMount, 500);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    refreshData = async () => {
        try {
            let uid = this.state.uid;
            let response = await request("/clients/short/" + uid, 'GET');
            if(response.error) {
                return;
            }

            if(response.vouchers) {
                response.vouchers = response.vouchers.filter((voc) => {
                    if(voc.quantity)
                        return voc
                })
            }

            await this.setState({
                short_id: response.short_id,
                name: response.name,
                balance: response.balance,
                transactions: response.transactions,
                vouchers: response.vouchers,
                vouchers_names: response.vouchers_names,
                unpaid: response.split_balance?.unpaid,
                spent: response.split_balance?.spent,
                age: response.age
            });
        } catch (error) {
            console.log(error.message);
        }
    }

    getErrorRender(mode) {
        return (

            <div>
                <Grid container alignItems='center' justifyContent="center" direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12}
                        style={(mode == "dark") ? customStyles.mainBackgroundDark : customStyles.mainBackground}
                    >
                        <div style={(mode == "dark") ? customStyles.subBackgroundDark : customStyles.subBackground}>
                            {this.braceletHeaderRender(mode)}

                            <div style={{textAlign: 'center'}}>
                                {(this.state.error_code == '|event_is_archived|') ? (
                                    <h4 style={(mode == "dark") ? {color: config.baseColorDark} : {color: config.baseColor}}>{translate('custom.event_is_over')}</h4>
                                ) : null}
                                {(this.state.error_code == '|wallets_are_not_open_yet|') ? (
                                    <>
                                        <h3 style={(mode == "dark") ? {color: config.baseColorDark} : {color: config.baseColor}}>{translate('custom.TEXT.wallets_will_be_opened_on_line1')}</h3>
                                        <h4 style={(mode == "dark") ? {color: config.baseColorDark} : {color: config.baseColor}}>{translate('custom.TEXT.wallets_will_be_opened_on_line2')}</h4>
                                    </>
                                ) : null}
                            </div>

                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }


    render(){
        let mode = this.props.match.params.mode;
        setTheme(mode);

        if (this.state.error) {
            return (
                <>
                    {this.getErrorRender(mode)}
                </>
            )
        }

        return (
            <>
                {this.getNewRender(mode)}
            </>
        )

    }

    braceletHeaderRender(mode) {

        let event_name = this.state?.general_information?.name ? this.state?.general_information?.name : null;

        return (

            <div style={{textAlign: 'center'}}>
                <h3 style={{color: (mode == "dark") ? config.baseColorDark : config.baseColor}}>{this.state.name ? this.state.name : this.state.short_id}</h3>

                {event_name ? (
                    <h4 style={{color: (mode == "dark") ? config.baseColorDark : config.baseColor}}>{event_name}</h4>
                ) : null}
            </div>

        )

    }

    braceletDetailsRender(mode) {
        return (
            <div>
                <h3 style={{textAlign: 'left'}}>{translate('custom.bracelet_details')} :</h3>

                <Hidden xsDown>
                    <Grid container justifyContent='space-around' style={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                            <h4>{translate('custom.balance')}</h4>
                            <h3 style={(this.state.balance < 0) ? {color: ((mode == "dark") ? config.dangerColorDark : config.dangerColor)} : {color: (mode == "dark") ? config.successColorDark : config.successColor}}>{this.currency} {formatCurrencySign(this.state.balance)}</h3>
                        </Grid>
                        {(this.state.age != null) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.age')}</p>
                                <h4 style={{color: (mode == "dark") ? config.baseColorDark : config.baseColor}}>{translate('custom.' + config.ages_reverse_map[this.state.age])}</h4>
                            </Grid>
                        : null}
                        {(this.state.unpaid > 0) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.unpaid')}</p>
                                <h4 style={{color: 'gray'}}>{this.currency} {formatCurrencySign(this.state.unpaid)}</h4>
                            </Grid>
                        : null}
                        {(this.state.spent != null) ?
                            <Grid item xs={3}>
                                <p>{translate('custom.total_spent')}</p>
                                <h4 style={{color: (mode == "dark") ? config.baseColorDark : config.baseColor}}>{this.currency} {formatCurrencySign(this.state.spent)}</h4>
                            </Grid>
                        : null}
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <div style={{margin: '20px'}}>
                        <h4>
                            <span>{translate('custom.balance')}</span>
                            <span style={{color: (mode == "dark") ? config.successColorDark : config.successColor, float: 'right', fontWeight: '800'}}>{this.currency} {formatCurrencySign(this.state.balance)}</span>
                        </h4>
                    </div>
                    {(this.state.unpaid > 0) ?
                        <div style={{margin: '20px'}}>
                            <h4>
                                <span>{translate('custom.unpaid')}</span>
                                <span style={{color: 'gray', float: 'right', fontWeight :'800'}}>{this.currency} {formatCurrencySign(this.state.unpaid)}</span>
                            </h4>
                        </div>
                    : null}
                    {(this.state.age != null) ?
                        <div style={{margin: '20px'}}>
                            <h4>
                                <span>{translate('custom.age')}</span>
                                <span style={{color: (mode == "dark") ? config.baseColorDark : config.baseColor, float: 'right', fontWeight: '800'}}>{translate('custom.' + config.ages_reverse_map[this.state.age])}</span>
                            </h4>
                        </div>
                    : null}
                </Hidden>

                

            </div>
        )
    }

    addMoneyRender(mode) {
        if(this.props.match.params.app)
            return(<></>)

        if(!this.state.general_information.acceptCreditCard)
            return(<></>)

        let end_date = new Date(this.state.general_information.dateEnd);
        let curr_date = new Date()
        if(curr_date > end_date)
            return(<></>)


        let enable_twint = this.state?.general_information?.twint?.enable_twint;
        let enable_cards = this.state?.general_information?.enable_cards;

        return (
            <div style={{textAlign: 'center'}}>



                <Grid container justifyContent='center' style={{textAlign: 'center'}}>
                    {/* <Hidden xsDown>
                        <Grid item sm={4} style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto'}}>
                            {this.state.animationCount == 3 ? <img src={rightAnimated1} style={{width: '50%', height: 'auto'}} /> : null}
                            {this.state.animationCount == 2 ? <img src={rightAnimated2} style={{width: '50%', height: 'auto'}} /> : null}
                            {this.state.animationCount == 1 ? <img src={rightAnimated3} style={{width: '50%', height: 'auto'}} /> : null}
                            {this.state.animationCount == 0 ? <img src={rightAnimated4} style={{width: '50%', height: 'auto'}} /> : null}
                        </Grid>
                    </Hidden> */}
                    {enable_twint ? (
                        <Grid item sm={12} md={6} lg={6} style={{verticalAlign: 'middle'}}>
                            <Link style={{textDecoration: 'none'}}
                                to={this.state.short_id ? '/client/'+this.state.short_id+'/pay?payment_type=twint&embed=true' : '#'}
                                target="_blank"
                            >
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: config.baseColor,
                                        color: 'white'
                                    }}
                                    startIcon={<CreditCardIcon />}
                                >
                                    {translate('custom.add_money_with_twint')}
                                </Button>
                            </Link>
                        </Grid>
                    ) : null}
                    
                    {this.state.general_information?.twint?.enable_twint_direct ? 
                        <Grid item sm={12} md={6} lg={6} style={{verticalAlign: 'middle'}}>
                            <Link style={{textDecoration: 'none'}}
                                to={this.state.short_id ? '/client/'+this.state.short_id+'/pay?payment_type=twint_direct&embed=true' : '#'}
                                target="_blank"
                            >
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: config.baseColor,
                                        color: 'white'
                                    }}
                                    startIcon={<CreditCardIcon />}
                                >
                                    {translate('custom.add_money_with_twint')}
                                </Button>
                            </Link>
                        </Grid>
                        : null
                    }
                </Grid>
                
                {(enable_cards) ? (
                    <Grid item sm={12} md={12} lg={12} style={{verticalAlign: 'middle', marginTop: 10}}>
                        <Link style={{textDecoration: 'none'}}
                            to={this.state.short_id ? '/client/'+this.state.short_id+'/pay?payment_type=other' : '#'}
                            target="_blank"
                        >
                            <Button
                                variant="outlined"
                                style={{
                                    borderColor: (mode == "dark") ? config.baseColorDark : config.baseColor,
                                    color: (mode == "dark") ? config.baseColorDark : config.baseColor
                                }}
                                startIcon={<CreditCardIcon />}
                            >
                                {translate('custom.recharge_with_other_methods')}
                            </Button>
                        </Link>
                    </Grid>
                ) : null}

            </div>
        )
    }

    // ----------------- TRANSACTIONS -------------------------

    getType(tran) {
        if (tran.type == 'credit' && tran.payment_type == 'wristband') {
            return 'credit_wristband';
        }
        return tran.type;
    }

    getTransactionIcon(tran) {
        let type = this.getType(tran);
        return (ICONS[type].icon);
    }

    getTransactionTitle(tran) {
        let type = this.getType(tran);
        return (ICONS[type].text);
    }

    getTransactionDate(tran) {
        let datetime = tran.date;
        return datetime.split(" ").slice(1).join(" ");
    }

    getTransactionTime(tran) {
        let datetime = tran.date;
        return datetime.split(" ")[0];
    }

    getTransactionType(tran) {
        return (ICONS[tran.type].type);
    }

    getTransactionTypeIcon(tran, mode) {

        if(ICONS[tran.type].type == "credited")
            return (
                <CreditedIcon
                    style={{color: 'white', fontSize: '15px', backgroundColor: ICONS[tran.type].color, padding: '2px', borderRadius: '3px'}}
                />
            )
        else if(ICONS[tran.type].type == "refunded")
            return (
                <RefundedIcon
                    style={{color: 'white', fontSize: '15px', backgroundColor: ICONS[tran.type].color, padding: '2px', borderRadius: '3px'}}
                />
            )
        else
            return (
                <DebitedIcon
                    style={{color: 'white', fontSize: '15px', backgroundColor: ICONS[tran.type].color, padding: '2px', borderRadius: '3px'}}
                />
            )
    }

    vouchersRender(mode) {
        return (
            <div>
            
                {this.state?.vouchers?.length > 0 && 
                    <>
                        <h3 style={{marginBottom: '0'}}>{translate('custom.vouchers')} :</h3>

                        <div style={{overflowY: 'auto', whiteSpace: 'nowrap'}}>
                            {this.state.vouchers.map((voucher, idx) => {
                                let voucher_name_obj = this.state.vouchers_names.find((voc) => voc._id === voucher.voucher_id);
                                return (

                                    <span key={idx}>
                                        <Button
                                            variant="contained"
                                            style={(mode == "dark") ? customStyles.voucherLeftDark : customStyles.voucherLeft}
                                            disabled
                                        >{voucher.quantity + ' x'}</Button>                                        
                                        <Button
                                            variant="contained"
                                            style={(mode == "dark") ? customStyles.voucherRightDark : customStyles.voucherRight}
                                            disabled
                                        >{voucher_name_obj.name}</Button>
                                    </span>

                                )
                            })}
                        </div>
                        
                    </>
                }
            
            </div>
        )
    }

    transactionOrdersRender(transaction) {
        
        if(transaction.card_transaction_id) {
            return (
                <div style={{backgroundColor: 'white', padding: '10px', margin: '20px', borderRadius: '10px'}}>
                    <div key={transaction.card_transaction_id}>
                        <ListItem>
                            <ListItemText
                                primary={<div style={{color: ICONS[transaction.type].color}}>{translate('custom.transaction')}</div>}
                            />
                            <ListItemSecondaryAction style={{marginRight: 10}}>
                                <ListItemText
                                    primary={<div style={{color: ICONS[transaction.type].color}}>{transaction.card_transaction_id}</div>}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </div>
                </div>
            )
        }

        let orders = transaction.order;
        if(!(orders))
            return null;

        orders.filter((ord) => ord.quantity ? ord : null);

        if(!orders || orders.length == 0)
            return null;

        let counter = 0;
        
        return (

            <div style={{backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px', margin: '1px', borderRadius: '10px'}}>
                {orders.map((order) => {
                    if(!(order.quantity))
                        return null;

                    return (
                        <div key={order._id + '_' + counter++}
                            // border bottom except last row
                            style={counter == (orders.length - 1) ? null : {borderBottom: '1px dashed darkgray', marginTop: 2, marginBottom: 2}}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <p style={{color: "#08607e"}}>{order.quantity + " x " + order.name}</p>
                                    {/* {order.deposit ? <p style={{margin: 5, color: "gray", fontSize: '12px'}}>{translate('custom.with') + ' ' + order.quantity + ' x ' + order.deposit.name + ' ' + translate('custom.of') + ' ' + this.currency + ' ' + formatCurrency(order.deposit.price)}</p> : null} */}
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} style={{display: 'flex', alignItems: 'center'}}>
                                    { order.voucher ? 
                                        <p style={{color: "#060", flex: 1, textAlign: 'right'}}>{order.voucher.name + " " + translate('custom.voucher')}</p> :
                                        <p style={{color: "#08607e", flex: 1, textAlign: 'right'}}>{formatCurrencySign(order.total * (transaction.type === config.transaction_types.CREDIT || transaction.type === config.transaction_types.UNPAID ? 1 : -1))}</p>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            </div>

        )
    }

    transactionsRender(isSmall, mode) {
        
        let prevTranDate = null;

        return (
            <div>
                <h3 style={{marginBottom: '0'}}>{translate('custom.transactions')} :</h3>

                <List>
                    {this.state.transactions.map((transaction, idx) => {

                        let icon = this.getTransactionIcon(transaction);
                        let title = this.getTransactionTitle(transaction);
                        let secondary_title = null;
                        let tranType = transaction.type;
                        let amount = transaction.amount  * (tranType === config.transaction_types.CREDIT || tranType === config.transaction_types.UNPAID ? 1 : -1);
                        let order_number = transaction.order_number ? transaction.order_number : '';
                        let table_number = transaction.table_number ? transaction.table_number : '';
                        let type = this.getTransactionType(transaction);
                        let typeIcon = this.getTransactionTypeIcon(transaction, mode);
                        let currTranDate = this.getTransactionDate(transaction);
                        let currTranDateDisplay = currTranDate;
                        let currTranTime = this.getTransactionTime(transaction);


                        if(tranType === config.transaction_types.ENTRANCE) {
                            secondary_title = transaction['order'][0]['name'];
                        } else if(tranType === config.transaction_types.ZONE) {
                            title = transaction['order'][0]['name']
                            // use the fields entrance begin date & entrance end date
                            secondary_title = transaction.begin_date ? new Date(transaction.begin_date).toLocaleString(translate('custom.OPTIONS.i18n_date_format'), {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}) : '';
                        } else if(tranType === config.transaction_types.CREDIT && (transaction.payment_type === config.payment_types.TWINT.value || transaction.payment_type === config.payment_types.CARD.value)) {
                            secondary_title = transaction.card_transaction_id;
                        } else if(tranType === config.transaction_types.CREDIT && transaction.payment_type === config.payment_types.TWINT_DIRECT.value) {
                            secondary_title = transaction.twint_direct_merchant_tran_id;
                        } else if(tranType === config.transaction_types.TWINT_REFUND) {
                            secondary_title = transaction?.card_transaction_id ? transaction.card_transaction_id : transaction?.twint_direct_merchant_tran_id ? transaction.twint_direct_merchant_tran_id : '';
                        }

                        if(prevTranDate != null && prevTranDate == currTranDate) {
                            currTranDateDisplay = '';
                            prevTranDate = currTranDate;
                        }

                        if(prevTranDate != null && prevTranDate != currTranDate) {
                            prevTranDate = currTranDate;
                        }

                        if(prevTranDate == null) {
                            prevTranDate = currTranDate;
                        }

                        return (
                            <div key={'transactionsRender-'+idx}>
                                {(currTranDateDisplay != '') && 
                                    (
                                        <ListItem style={isSmall ? {padding: 0} : {paddingTop: 0, paddingBottom: 0}}>
                                            <ListItemText>
                                                {(idx != 0) &&
                                                    <Divider style={{marginBottom: '10px', height: '2px'}} />
                                                }
                                                {currTranDateDisplay}
                                            </ListItemText>
                                        </ListItem>
                                    )
                                }
                                
                                
                                <ListItem style={isSmall ? {padding: 0, height: 60, marginTop: 10, marginBottom: 10} : {paddingTop: 0, paddingBottom: 0}}
                                    onClick={()=>{
                                        if(!canCollapse)
                                            return;
                                        var new_state = {};
                                        new_state[transaction._id] = !this.state[transaction._id];
                                        this.setState(new_state);
                                    }}
                                >

                                    <ListItemText style={isSmall ? {minWidth: '80%'} : {minWidth :'40%'}} >
                                        <Button
                                            disabled
                                            style={{color: (mode == "dark") ? 'white' : 'black', textTransform: 'none'}}
                                        >
                                            {icon}
                                            <ListItemText
                                                style={{paddingLeft: '10px', textAlign: 'left'}}
                                                primary={<b>{title}</b>}
                                                secondary={
                                                    <span style={{color: (mode == "dark") ? 'white' : 'black'}}>
                                                        {secondary_title ? (<Hidden smDown>{secondary_title}</Hidden>) : null }
                                                        <Hidden mdUp>{currTranTime} {secondary_title ? "- " + secondary_title : null}</Hidden>
                                                        <div>
                                                            {order_number ? translate('custom.order_number') + ' - ' + order_number : ''} {table_number ? ' [ ' + translate('custom.table_number') + ' - ' + table_number + ' ]' : ''}
                                                        </div>
                                                    </span>
                                                }
                                            />
                                        </Button>
                                    </ListItemText>
                                    <Hidden smDown>
                                        <ListItemText style={{minWidth: '25%'}}>
                                            <Button
                                                disabled
                                                style={{color: (mode == "dark") ? 'white' : 'black', textTransform: 'none'}}
                                            >
                                                {typeIcon}
                                                &nbsp;
                                                {translate('custom.'+type)}
                                            </Button>
                                        </ListItemText>
                                        <ListItemText style={{textAlign: 'right'}}>
                                            {currTranTime}
                                        </ListItemText>
                                    </Hidden>
                                    <ListItemText style={{textAlign: 'right', color: (mode == "dark") ? ICONS[transaction.type].colorDark : ICONS[transaction.type].color, width: '100%', paddingRight: '20px'}}>
                                        {formatCurrencySign(amount)}
                                    </ListItemText>
                                    <ListItemText style={{textAlign: 'right'}}>
                                        {canCollapse(transaction) ? this.state[transaction._id] ? <ExpandLess /> : <ExpandMore /> : <ExpandLess style={{visibility: 'hidden'}} />}
                                    </ListItemText>
                                </ListItem>
                                {canCollapse(transaction) ? 
                                    <Collapse in={this.state[transaction._id]} timeout="auto" unmountOnExit>
                                        {this.transactionOrdersRender(transaction)}
                                    </Collapse>
                                    : null
                                }
                                
                            </div>
                        )
                    })}
                </List>
            </div>
        )
        
    }

    // --------------- TRANSACTIONS ENDS --------------------

    getLoadingRender() {
        return (
            <div style={{textAlign: 'center', marginTop: '20px'}}>
                <Typography variant="h5" component="h3" style={{color: 'gray'}}>
                    {translate('custom.loading')}
                </Typography>
            </div>
        )
    }

    getClientNotFoundRender() {
        return (
            <div style={{textAlign: 'center', marginTop: '20px'}}>
                <Typography variant="h5" component="h3" style={{color: 'gray'}}>
                    {translate('custom.client_not_found')}
                </Typography>
            </div>
        )
    }

    getNewRender(mode) {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <div>

                <Grid container alignItems='center' justifyContent="center" direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12}
                        style={(mode == "dark") ? customStyles.mainBackgroundDark : customStyles.mainBackground}
                    >
                        {this.state.loading ? (this.getLoadingRender()) : (
                            this.state.client_found ? (
                                <>
                                    <div style={(mode == "dark") ? customStyles.subBackgroundDark : customStyles.subBackground}>
                                        {this.braceletHeaderRender(mode)}
                                        {this.braceletDetailsRender(mode)}
                                        {this.addMoneyRender(mode)}
                                    </div>
                                    
                                    {this.vouchersRender(mode)}
                                    {this.transactionsRender(isSmall, mode)}
                                </>
                            ) : (this.getClientNotFoundRender())
                        )}
                        

                    </Grid>
                </Grid>
            
            </div>
        );
    }
}

const styles = theme => ({
});
export default withTheme(withStyles(styles)(ClientEmbed));