import React from 'react';
import {
    NumberInput,
    NumberField,
    DateField,
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    DateTimeInput,
    required,
    SelectArrayInput,
    SimpleList,
    FormWithRedirect,
    SaveButton,
    DeleteButton,
    AutocompleteArrayInput,
    ArrayInput,
    SelectInput,
    SimpleFormIterator,
    Pagination,
    Toolbar,
    useRefresh,
    Filter,
    FormDataConsumer,
    BooleanInput,
} from 'react-admin';
import config from '../config';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import Actions from '../components/reactAdmin/Actions';
import EntranceTypeField from "../fields/EntranceTypeField";
import EntranceTypeInput from "../fields/EntranceTypeInput";
import RightInput from '../fields/RightInput';
import { useMediaQuery } from '@material-ui/core';
import { formatCurrency, formatCurrencyWithCode, getChoices, getLabel, request } from '../util';
import { translate } from "../i18n/customI18nProvider";
import { validatePositiveNumber, validatePositiveNumberWithMax } from '../validators/number_validators';
import Chip from '@material-ui/core/Chip';
import RestoreIcon from '@material-ui/icons/Restore';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export const EntranceIcon = ConfirmationNumber;

const QuickFilter = ({ label }) => {
    return <Chip style={{margin: 8, marginLeft: 0}} label={translate(label)} />;
};

const EntranceListFilter = (props) => (
    <Filter {...props}>
        <QuickFilter source="deleted_only" label={'custom.deleted_only'} defaultValue={true} />
        <QuickFilter source="with_deleted" label={'custom.with_deleted'} defaultValue={true} />
    </Filter>
);

const EntranceListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CustomButtons = ({record}) => {
    const refresh = useRefresh();
    if(record.deleted) {
        return (
            <>
                <Button
                    color="primary"
                    startIcon={<RestoreIcon />}
                    onClick={async () => {
                        try {
                            await request('/entrances/' + record._id + '/restore', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{marginLeft: '10px', marginRight: '10px'}}
                >
                    {translate('custom.restore')}
                </Button>
            </>
        )
    } else {
        return (
            <>
                <EditButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
            </>
        )
    }
}

export const EntranceList = (props) => {
    
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // let isSmall = false;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
    //     isSmall = true;
    // }
    
    return (
        <List {...props}
            actions={<Actions />}
            filters={<EntranceListFilter />}
            pagination={<EntranceListPagination />}
            perPage={100}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => getLabel(record.type, config.entrance_types)}
                    tertiaryText={record => formatCurrencyWithCode(record.price, props.options.currencyCode)}
                />
            ) : (
                <Datagrid>
                    <TextField label={translate('custom.name')} source="name" />
                    <NumberField label={translate('custom.price')} source="price" locales={translate('custom.OPTIONS.i18n_number_format')} options={{ style: 'decimal', minimumFractionDigits: 2 }}/>
                    <EntranceTypeField label={translate('custom.entry_type')} source="type" />
                    <DateField label={translate('custom.start_date')} source="display_begin_date" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                    <DateField label={translate('custom.end_date')} source="display_end_date" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                    <CustomButtons />
                </Datagrid>
            )}
        </List>
    );
};

const PostTitle = ({ record }) => {
    return <span>{translate('custom.entrance')} {record ? `"${record.name}"` : ''}</span>;
};

const DepositTextRender = ({ record, currencyCode }) => {
    return (
        <>
            <Button
                disabled={true}
                size="small"
                style={{color: 'black', textTransform: 'none'}}
            >{record.name + ' ' + translate('custom.of') + ' ' + (currencyCode ? (formatCurrencyWithCode(record.price, currencyCode)) : (formatCurrency(record.price)))}</Button>
        </>
    );
}

let entrances = [];

const entrances_form = (max_entrance_amount, deposits, currencyCode) => {

    return (
        <>
            <h3 style={headerTextStyles}>{translate('custom.informations')}</h3>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
                    <TextInput fullWidth={true} source="name" label={translate('custom.name')} validate={required()} autoFocus variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <NumberInput fullWidth={true} source="price" label={translate('custom.price')} step={0.01} validate={max_entrance_amount ? validatePositiveNumberWithMax(max_entrance_amount) : validatePositiveNumber} variant='outlined' onWheel={(e) => e.target.blur()} />
                </Grid>
            </Grid>


            <h3 style={headerTextStyles}>{translate('custom.configuration')}</h3>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={4} xl={3}>
                    <SelectArrayInput fullWidth={true} source="ages" label={translate('custom.ages')} choices={getChoices(config.entrance_ages)} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <EntranceTypeInput fullWidth={true} source="type" label={translate('custom.entrance_type')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <RightInput fullWidth={true} source="min_right" label={translate('custom.minimum_permission')} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <SelectArrayInput fullWidth={true} source="payment_types" label={translate('custom.payment_types')} choices={getChoices(config.payment_types)} variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <NumberInput fullWidth={true} source="sort_order" label={translate('custom.sort_order')} variant='outlined' onWheel={(e) => e.target.blur()} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <BooleanInput fullWidth={true} source="allow_negative_balance" label={translate('custom.allow_negative_balance')} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                    <SelectInput
                        variant='outlined'
                        fullWidth
                        source={"deposit"} label={translate('custom.deposit')}
                        choices={deposits}
                        resettable={true}
                        optionText={<DepositTextRender currencyCode={currencyCode} />}
                        style={{"width": "100%"}}
                        // record={scopedFormData}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <AutocompleteArrayInput fullWidth source="ticket_category_id" label={translate('custom.ticket_category_id')} choices={myChoices} style={{margin: '10px'}} variant='outlined'
                        defaultHighlightedIndex={0}
                        onCreate={(text) => {
                            if(text === "")
                                text = prompt(translate('custom.enter_a_new_one_to_create'));
                            const newTag = { id: Number(text), name: text };
                            myChoices.push(newTag);
                            return newTag;
                        }}
                        suggestionLimit={5}
                    />
                </Grid> */}
            </Grid>

            


            <h3 style={headerTextStyles}>{translate('custom.display')}</h3>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <DateTimeInput fullWidth={true} source="display_begin_date" label={translate('custom.display_begin_date')} locales="fr-CH" variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <DateTimeInput fullWidth={true} source="display_end_date" label={translate('custom.display_end_date')} locales="fr-CH" variant='outlined' />
                </Grid>
            </Grid>


            <h3 style={headerTextStyles}>{translate('custom.entrance_validity')}</h3>
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <DateTimeInput fullWidth={true} source="entrance_begin_date" label={translate('custom.entrance_valid_from')} locales="fr-CH" variant='outlined' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <DateTimeInput fullWidth={true} source="entrance_end_date" label={translate('custom.entrance_valid_to')} locales="fr-CH" variant='outlined' />
                </Grid>
            </Grid>


            

            
        </>
    );
}

const voucher_packs_form = (voucher_packs) => {
    return (
        <>
            <h3 style={headerTextStyles}>{translate('custom.voucher_packs')}</h3>
            <ArrayInput source="voucher_packs">
                <SimpleFormIterator disableReordering>

                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            return (
                                <>

                                    <Grid container style={{width: '100%'}} spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                            <SelectInput
                                                fullWidth={true} label={translate('custom.voucher_packs')} source={getSource("voucher_pack_id")} choices={voucher_packs} validate={required()}
                                                variant='outlined'
                                                record={scopedFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                            <NumberInput
                                                fullWidth={true} source={getSource("quantity")} label={translate('custom.quantity')} validate={validatePositiveNumber}
                                                step={1} variant='outlined'
                                                record={scopedFormData}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </Grid>
                                    </Grid>

                                </>

                            );
                        }}
                    </FormDataConsumer>
                    
                </SimpleFormIterator>
            </ArrayInput>
        </>
    )
};

const validateEntranceForm = async (values) => {
    const errors = {};

    let display_begin_date = new Date(values.display_begin_date);
    let display_end_date = new Date(values.display_end_date);
    if(display_begin_date > display_end_date) {
        errors['display_begin_date'] = translate('custom.begin_date_cannot_be_greater_than_end_date');
    }

    let entrance_begin_date = new Date(values.entrance_begin_date);
    let entrance_end_date = new Date(values.entrance_end_date);
    if(entrance_begin_date > entrance_end_date) {
        errors['entrance_begin_date'] = translate('custom.begin_date_cannot_be_greater_than_end_date');
    }


    let found = entrances.find((ent) => ent.name === values.name);
    if(found) {
        errors['name'] = translate('custom.this_name_is_already_taken');
    }

    // if(values.ticket_category_id && values.ticket_category_id.length > 0) {
    //     for(let i=0; i<values.ticket_category_id.length; i++) {
    //         if(values.ticket_category_id[i] === "" || !values.ticket_category_id[i]) {
    //             errors['ticket_category_id'] = translate('custom.ticket_category_id_cannot_be_empty_or_zero');
    //         }
    //     }
    // }

    if(values.sort_order) {
        if(values.sort_order != parseInt(values.sort_order)) {

            errors.sort_order = translate('custom.sort_order_must_be_an_integer');
        }
    }

    let voucher_packs = values.voucher_packs;

    if(voucher_packs) {
        errors.voucher_packs = [];
        let voucher_packs_length = voucher_packs.length;
        for(let i=0; i<voucher_packs_length; i++) {
            errors.voucher_packs.push({});

            if(voucher_packs[i]) {
                if(!voucher_packs[i].quantity) {
                    if(!errors.voucher_packs)
                        errors.voucher_packs = [];
                    if(!errors.voucher_packs[i])
                        errors.voucher_packs[i] = {};
                    errors.voucher_packs[i].quantity = translate('custom.quantity_is_required');
                } else if(voucher_packs[i].quantity < 1) {
                    if(!errors.voucher_packs)
                        errors.voucher_packs = [];
                    if(!errors.voucher_packs[i])
                        errors.voucher_packs[i] = {};
                    errors.voucher_packs[i].quantity = translate('custom.quantity_must_be_greater_than_0');
                } else if(voucher_packs[i].quantity != parseInt(voucher_packs[i].quantity)) {
                    if(!errors.voucher_packs)
                        errors.voucher_packs = [];
                    if(!errors.voucher_packs[i])
                        errors.voucher_packs[i] = {};
                    errors.voucher_packs[i].quantity = translate('custom.quantity_must_be_an_integer');
                }
            }

        }
        
        for(let i=voucher_packs_length-1; i>=0; i--) {
            for (let j=0; j<i; j++) {
                if (voucher_packs && voucher_packs[i] && voucher_packs[j] && (voucher_packs[i]["voucher_pack_id"] === voucher_packs[j]["voucher_pack_id"])) {
                    errors.voucher_packs[i]['voucher_pack_id'] = translate('custom.same_voucher_pack_not_allowed');
                }
            }
        }
    }

    // -------- Ticket Category ID Repeat Check ------------------
    // let entrees = await request('/entrances', 'GET', token);
    // let all_ticket_category_ids = [];
    
    // for(let i=0; i<entrees.length; i++) {
    //     if((entrees[i]['_id'] === values['_id']) && ("ticket_category_id" in values))
    //         all_ticket_category_ids = all_ticket_category_ids.concat(values['ticket_category_id']);
    //     else if ("ticket_category_id" in entrees[i])
    //         all_ticket_category_ids = all_ticket_category_ids.concat(entrees[i]['ticket_category_id']);
    // }
    // let unique_ticket_category_ids = [...new Set(all_ticket_category_ids)];

    // if(all_ticket_category_ids.length !== unique_ticket_category_ids.length) {
    //     errors['ticket_category_id'] = translate('custom.ticket_category_id_is_already_taken');
    // }
    
    return errors
}

const SaveAndAddNewToolbar = (props) => {
    const refresh = useRefresh();
    return (
        <Toolbar>
            <SaveButton
                {...props}
                label="ra.action.save"
                redirect="edit"
                submitOnEnter={false}
                style={{marginRight: 10}}
            />
            <SaveButton
                {...props}
                label={translate('custom.save_and_add')}
                redirect={() => {
                    refresh();
                    return 'create';
                }}
                submitOnEnter={true}
            />
        </Toolbar>
    )
}

export const form = (state) => {

    return (
        <>
            {entrances_form(state.max_entrance_amount, state.deposits, state.currencyCode)}
            {voucher_packs_form(state.voucher_packs)}
        </>
    );
}

export class EntranceEdit extends React.Component {

    constructor() {
        super();

        this.state = {
            voucher_packs: [],
            // ticket_category_id_choices: [],
            max_entrance_amount: 0,
            currencyCode: '',
            deposits: []
        };
        entrances = [];
    }

    async componentDidMount() {
        let voc_packs = [];
        let ticket_cats = [];
        try {
            let voucher_packs = await request('/voucher_packs', 'GET', null);
            if(voucher_packs) {
                if (voucher_packs.error) {
                    alert(voucher_packs.error);
                } else {
                    for (let i = 0; i < voucher_packs.length; i++) {
                        voc_packs.push({
                            id: voucher_packs[i]._id,
                            name: voucher_packs[i].name
                        });
                    }
                    if(voc_packs.length)
                        voc_packs.sort((a, b) => a.name > b.name);
                    this.setState({
                        voucher_packs: voc_packs
                    })
                }
            
            }

            let general_information = await request('/events/general', 'GET', null);
            if(general_information.error) {
                alert(general_information.error);
            } else {
                this.setState({
                    max_entrance_amount: general_information.maxEntranceAmount,
                    currencyCode: general_information.currencyCode
                });
            }

            let deposits = await request('/deposits', 'GET', null);
            if(deposits.error) {
                alert(deposits.error);
            } else {
                this.setState({deposits: deposits});
            }

        } catch(e) {
            console.log("Error", String(e));
        }
        
    }

    render() {

        return (
            <Edit undoable={false} title={<PostTitle />} {...this.props}>
                <SimpleForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={validateEntranceForm}>
                    {form(this.state)}
                </SimpleForm>
            </Edit>
        );
    }


}

export class EntranceCreate extends React.Component {
    constructor() {
        super();

        this.state = {
            voucher_packs: [],
            // ticket_category_id_choices: [],
            max_entrance_amount: 0,
            currency_code: '',
            deposits: []
        };
        entrances = [];
    }

    async componentDidMount() {
        let voc_packs = [];
        try {
            let ents = await request('/entrances', 'GET', null);
            if(ents) {
                if(!ents.error) {
                    entrances = ents;
                }
            }
            let voucher_packs = await request('/voucher_packs', 'GET', null);
            if(voucher_packs) {
                if (voucher_packs.error) {
                    alert(voucher_packs.error);
                } else {
                    for (let i = 0; i < voucher_packs.length; i++) {
                        voc_packs.push({
                            id: voucher_packs[i]._id,
                            name: voucher_packs[i].name
                        });
                    }
                    if(voc_packs.length)
                        voc_packs.sort((a, b) => a.name > b.name);

                    this.setState({
                        voucher_packs: voc_packs
                    });
                }
            
            }

            let general_information = await request('/events/general', 'GET', null);
            if(general_information.error) {
                alert(general_information.error);
            } else {
                this.setState({
                    max_entrance_amount: general_information?.maxEntranceAmount,
                    currencyCode: general_information.currencyCode
                })
            }

            let deposits = await request('/deposits', 'GET', null);
            if(deposits.error) {
                alert(deposits.error);
            } else {
                this.setState({deposits: deposits});
            }

        } catch(e) {
            console.log("Error", String(e));
        }
    }

    render() {
        
        // let isSmall = false;
        // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
        //     isSmall = true;
        // }

        return (
            <Create title={translate('custom.create_entrance')} {...this.props}>
                <SimpleForm variant="standard" warnWhenUnsavedChanges validate={validateEntranceForm}
                    toolbar={<SaveAndAddNewToolbar />}
                >
                    {form(this.state)}
                </SimpleForm>
            </Create>
        );
    }
}

const headerTextStyles = {
    width: '100%',
    borderBottom: '2px solid' + config.baseColor,
    marginBottom: '50px',
    color: config.baseColor
}