import React, {Component} from 'react';

import {
    FileInput,
    FileField,
    SimpleForm,
    useRefresh,
    // SimpleForm,
    // Toolbar,
    // SaveButton,
} from 'react-admin';

import Button  from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {convertMessageCodeToMessage, downloadFileFromResponse, getRequestOptions, request, requestMultipart} from "../../util";
import {translate} from "../../i18n/customI18nProvider";
import Grid from '@material-ui/core/Grid';
import config from '../../config';
import Swal from 'sweetalert2';

class ImportBarsClass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            update_if_already_exists: false,
            delete_if_not_exists: false,
            loading: false
        };

        this.importbars = this.importbars.bind(this);
    }

    async importbars() {

        this.props.refresh();

        await this.setState({loading: true});

        if (!this.state.selectedFile) {
            alert(translate('custom.please_select_file'));
            return;
        }

        let requestBody = new FormData();
        requestBody.append('bars_data', this.state.selectedFile);
        requestBody.append('update_if_already_exists', this.state.update_if_already_exists);
        requestBody.append('delete_if_not_exists', this.state.delete_if_not_exists);
        let response;
        try {
            response = await requestMultipart('/bars/import', 'POST', null, requestBody);
            response = response.data;
        } catch (e) {
            console.log("E", e)
            response = e.response.data;
        }

        if(response.error) {
            let error_message = response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error;
            alert(error_message);
            this.setState({loading: false});
            return
        }

        let response_string = `${translate('custom.import_completed_with')} \n${translate('custom.success')} - ${response.success} \n${translate('custom.failure')} - ${response.failure}`
        alert(response_string);

        this.props.refresh();
    };

    async handleChange(e) {
        if (e) {
            this.setState({selectedFile: e, loading: false})
        } else {
            this.setState({selectedFile: null, loading: false})
        }
    }

    downloadTemplate = async () => {
        this.setState({loading: true});
        let response = await fetch(config.apiUrl + '/bars/download_template', getRequestOptions('GET'));
        if(response.status !== 200) {
            let responseJson = await response.json();
            Swal.fire({
                title: translate('custom.error'),
                text: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
                icon: 'error',
                confirmButtonText: translate('custom.ok'),
                confirmButtonColor: config.baseColor
            });
            this.setState({loading: false});
            return;
        } else {
            await downloadFileFromResponse(response, translate('custom.bars') + '.xlsx', false);
            this.setState({loading: false});
        }
    }


    render() {
        return (
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'center', border: '1px solid lightgray', margin: '10px', borderRadius: '10px'}}>

                    <SimpleForm toolbar={null} >
                        <FileInput
                            source="files"
                            label={translate('custom.import_consumptions_by_uploading_xlsx_file')}
                            multiple={false}
                            onChange={(e) => {
                                this.handleChange(e)
                            }}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    </SimpleForm>

                    <FormGroup row style={{flex: 1, justifyContent: 'space-around'}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.update_if_already_exists}
                                    onChange={(e) => {
                                        this.setState({update_if_already_exists: e.target.checked})
                                    }}
                                    name="update_if_already_exists"
                                    color="primary"
                                />
                            }
                            label={translate('custom.update_if_already_exists')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.delete_if_not_exists}
                                    onChange={(e) => {
                                        this.setState({delete_if_not_exists: e.target.checked})
                                    }}
                                    name="delete_if_not_exists"
                                    color="primary"
                                />
                            }
                            label={translate('custom.delete_if_not_exists')}
                        />
                    </FormGroup>

                    <Button
                        color="primary"
                        onClick={this.importbars}
                        variant="contained"
                        size="small"
                        style={{marginLeft: '5px', marginRight: '5px'}}
                    >
                        {translate('custom.import')}
                    </Button>
                    
                    <Button
                        color="primary"
                        onClick={this.downloadTemplate}
                        variant="contained"
                        size="small"
                        style={{marginLeft: '5px', marginRight: '5px'}}
                    >
                        {translate('custom.download_template')}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

// export default ImportBars;
const ImportBars = (props) => {
    const refresh = useRefresh();
    return <ImportBarsClass refresh={refresh} {...props} />
}
export default ImportBars;
